import React from 'react'

import { Tooltip } from '../Tooltip'

const BetaToolTip = ({ disableTooltip, children }: React.PropsWithChildren & { disableTooltip: boolean }) => (
  <Tooltip
    disabled={disableTooltip}
    content={
      <div>
        <div className="font-medium">Beta feature</div>
        <div className="mt-2">This feature is likely to continue to evolve and could become an add-on eventually.</div>
      </div>
    }
  >
    {children}
  </Tooltip>
)

export default BetaToolTip
