import { ToastType, useToast } from '@packages/sk8/toast'
import { AdminUserRole } from '@packages/types'
import React from 'react'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import CreateUserForm from 'cms/users/components/CreateUserForm'
import { trpc } from 'common/hooks/trpc'
import useGoBack from 'common/hooks/useGoBack'

const roleOptions = [{ value: AdminUserRole.MczrAdmin, label: 'MCZR Admin' }]

const CreateAdminUser = () => {
  const goBack = useGoBack()
  const { openToast, openGenericErrorToast } = useToast()

  const { mutate: createUser } = trpc.adminUser.create.useMutation({
    onSuccess: () => {
      openToast('User was successfully created!', ToastType.success)
      goBack()
    },
    onError: (error, { email }) => {
      if (error.data?.code === 'CONFLICT') {
        openToast(`The email ${email} is already in use by another user`, ToastType.warning)
      } else {
        openGenericErrorToast('User has not been created.')
      }
    },
  })

  return (
    <main>
      <Header />
      <SideMenu />

      <Page>
        <CreateUserForm
          roleOptions={roleOptions}
          onSubmit={(values, helpers) =>
            createUser(
              { ...values, role: values.role as AdminUserRole.MczrAdmin },
              { onSettled: () => helpers.setSubmitting(false) }
            )
          }
          onCancel={goBack}
        />
      </Page>
    </main>
  )
}

export default CreateAdminUser
