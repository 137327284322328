import isNullOrEmpty from 'utils/isNullOrEmpty'

export const addUnits = (value: number, units?: string) => {
  if (isNullOrEmpty(units)) return `${value}`

  if (units === '$') {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
  }

  return `${value} ${units}`
}
