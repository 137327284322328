import { Button } from '@packages/sk8/button'
import { Checkbox, HelperText, Input, InputField, Label, PasswordInput, Select } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { PaymentStrategy, Plan, PlanName, TransactionFeeType } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import * as yup from 'yup'

import PlanCard from 'cms/subscription/components/cards/PlanCard'
import CustomPlanForm from 'cms/subscription/components/CustomPlanForm'
import useCustomPlanForm, { CreateCustomPlanFormValues } from 'cms/subscription/hooks/useCustomPlanForm'
import { NetworkError } from 'common/api/types/error'
import { trpc } from 'common/hooks/trpc'

import useBrandService from './../hooks/useBrandService'
import type { CreateBrandFormValues } from './../types/form'
import type { Tenant } from './../types/tenant'

const customPlan: CreateCustomPlanFormValues = {
  name: PlanName.Custom,
  custom: true,
  features: { productLimit: 0, whiteLabel: true },
  price: {
    type: TransactionFeeType.Flat,
    recurringAmount: 0,
    transactionFeePercentage: 0,
    cappedAmount: 100000,
  },
  attributesText: [
    'Unlimited products',
    'Unlimited pageviews',
    'Unlimited storage',
    'Dozens of feature updates every year',
    'No setup fee or other hidden fees',
    'White label',
  ],
}

const domainRegex = RegExp(/^([a-z0-9]+(-|)){2,}[a-z0-9]$/)
export interface CreateBrandModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

const CreateBrandModal = ({ onClose, ...modalProps }: CreateBrandModalProps) => {
  const { openToast } = useToast()
  const queryClient = useQueryClient()
  const brandService = useBrandService()

  const { data: plans = [] } = trpc.plan.listAvailable.useQuery()

  const availablePlans = plans.filter(plan => !!plan.available)
  const plansOptions = [...availablePlans, customPlan].map(plan => ({ label: plan.name, value: plan }))

  const verifyBrandName = async (brandName?: string | null): Promise<boolean> => {
    if (!brandName) return true

    try {
      const { available } = await brandService.verifyBrandName({ brandName })
      return available
    } catch (error) {
      return false
    }
  }

  const formValidation = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required('Please enter a brand name')
          .min(3, 'Brand name must be at least 3 characters long')
          .matches(
            domainRegex,
            'Brand name must consist only of lowercase alphanumeric characters. It cannot end with a "."'
          )
          .max(26, 'Brand name must be at most 26 characters')
          .test('verifyBrandName', 'This brand name already exists', verifyBrandName),
        firstName: yup.string().required('Please enter a first name'),
        lastName: yup.string().required('Please enter a last name'),
        email: yup.string().required('Please enter an email address').email('Please enter a valid email'),
        password: yup
          .string()
          .required('Please enter a password')
          .min(5, 'Password must be at least 5 characters long'),
      }),
    []
  )

  const { mutate: createBrand } = useMutation<Tenant, NetworkError, CreateBrandFormValues>(brandService.create, {
    onSuccess: () => {
      openToast('Brand was successfully created', ToastType.success)
      handleClose()
      queryClient.invalidateQueries(brandService.fetch.queryKeys)
    },
  })

  const planFormik = useCustomPlanForm({
    onSubmit: () => {},
    initialValues: customPlan,
  })

  const formik = useFormik<Omit<CreateBrandFormValues, 'plan'>>({
    initialValues: {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      paymentStrategy: PaymentStrategy.Manual,
      isTest: false,
      areQuotesAllowed: false,
      areCustomStoresAllowed: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      createBrand({ ...values, plan: planFormik.values }, { onSettled: () => setSubmitting(false) })
    },
    validationSchema: formValidation,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const paymentStrategyOptions = [{ value: PaymentStrategy.Manual, label: 'Manual' }]

  useEffect(() => {
    const selectedPlan = plans.find(x => x.name === planFormik.values.name)

    if (selectedPlan) {
      formik.setFieldValue('plan.price', selectedPlan?.price)
    }
  }, [planFormik.values, plans])

  return (
    <Modal onBackdropClick={handleClose} {...modalProps} size="large">
      <div className="flex max-h-[90vh]">
        <form
          onSubmit={formik.handleSubmit}
          data-testid="create-brand-form"
          noValidate
          className="basis-1/2 flex flex-col"
        >
          <Modal.CloseButton onClick={handleClose} />
          <Modal.Title>Create new brand</Modal.Title>

          <Modal.Details className="grow overflow-y-auto max-h-[700px]">
            <div className="flex flex-col space-y-6 text-neutral-900">
              <InputField>
                <Label htmlFor="name">Brand name</Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter brand name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.name && formik.errors.name != null}
                />
                {formik.touched.name && formik.errors.name != null && (
                  <HelperText hasError>{formik.errors.name}</HelperText>
                )}
              </InputField>

              <div className="flex justify-between space-x-2">
                <InputField className="flex-1">
                  <Label htmlFor="firstName">First name</Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.firstName && formik.errors.firstName != null}
                  />
                  {formik.touched.firstName && formik.errors.firstName != null && (
                    <HelperText hasError>{formik.errors.firstName}</HelperText>
                  )}
                </InputField>

                <InputField className="flex-1">
                  <Label htmlFor="lastName">Last name</Label>
                  <Input
                    id="lastName"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.lastName && formik.errors.lastName != null}
                  />
                  {formik.touched.lastName && formik.errors.lastName != null && (
                    <HelperText hasError>{formik.errors.lastName}</HelperText>
                  )}
                </InputField>
              </div>

              <div className="flex justify-between space-x-2">
                <InputField className="flex-1">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.email && formik.errors.email != null}
                  />
                  {formik.touched.email && formik.errors.email != null && (
                    <HelperText hasError>{formik.errors.email}</HelperText>
                  )}
                </InputField>

                <InputField className="flex-1">
                  <Label htmlFor="password">Password</Label>
                  <PasswordInput
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.password && formik.errors.password != null}
                  />
                  {formik.touched.password && formik.errors.password != null && (
                    <HelperText hasError>{formik.errors.password}</HelperText>
                  )}
                </InputField>
              </div>

              <div className="flex justify-between space-x-2">
                <InputField className="flex-1">
                  <Label htmlFor="paymentStrategy">Payment method</Label>
                  <Select<PaymentStrategy>
                    id="paymentStrategy"
                    name="paymentStrategy"
                    placeholder="Enter payment method"
                    disabled={formik.values.areCustomStoresAllowed}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={paymentStrategyOptions.find(option => option.value === formik.values.paymentStrategy)}
                    options={paymentStrategyOptions}
                    onChange={option => formik.setFieldValue('paymentStrategy', option?.value)}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.paymentStrategy && formik.errors.paymentStrategy != null}
                  />
                  {formik.touched.paymentStrategy && formik.errors.paymentStrategy != null && (
                    <HelperText hasError>{formik.errors.paymentStrategy}</HelperText>
                  )}
                </InputField>

                <InputField className="flex-1">
                  <Label htmlFor="plan">Plan</Label>
                  <Select<Plan | CreateCustomPlanFormValues>
                    id="plan"
                    name="plan"
                    placeholder="Enter plan"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={plansOptions.find(option => option.value.name === planFormik.values.name)}
                    options={plansOptions}
                    onChange={option => planFormik.setValues(option?.value as CreateCustomPlanFormValues)}
                    onBlur={planFormik.handleBlur}
                  />
                </InputField>
              </div>

              {planFormik.values.custom && <CustomPlanForm formik={planFormik} />}
              <div className="flex space-x-2">
                <div className="grow flex space-x-2">
                  <Checkbox
                    id="allow-quotes"
                    name="areQuotesAllowed"
                    checked={formik.values.areQuotesAllowed}
                    onChange={e => formik.setFieldValue('areQuotesAllowed', e.target.checked)}
                  />
                  <Tooltip content="Allowing quotes will prevent a brand from being flagged with 0$ orders.">
                    <Label htmlFor="allow-quotes">Allow quotes</Label>
                  </Tooltip>
                </div>
                <div className="grow flex space-x-2">
                  <Checkbox
                    id="is-test"
                    name="isTest"
                    checked={formik.values.isTest}
                    onChange={e => formik.setFieldValue('isTest', e.target.checked)}
                  />
                  <Tooltip content="Allow filtering of test brands.">
                    <Label htmlFor="is-test">Test brand</Label>
                  </Tooltip>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="grow flex space-x-2">
                  <Checkbox
                    id="allow-custom-stores"
                    name="areCustomStoresAllowed"
                    checked={formik.values.areCustomStoresAllowed}
                    onChange={e => {
                      if (e.target.checked) {
                        formik.setFieldValue('paymentStrategy', PaymentStrategy.Manual)
                      }
                      formik.setFieldValue('areCustomStoresAllowed', e.target.checked)
                    }}
                  />
                  <Tooltip content="Allow custom stores, only available on custom plans">
                    <Label htmlFor="allow-custom-stores">Allow custom stores</Label>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Modal.Details>

          <Modal.Actions>
            <Button
              type="button"
              variant="default"
              className="px-4"
              onClick={handleClose}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              id="create-brand"
              type="button"
              variant="primary"
              className="px-4"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid || !planFormik.isValid}
              onClick={() => {
                Promise.all([formik.validateForm(), planFormik.validateForm()])
                  .then(() => {
                    formik.handleSubmit()
                  })
                  .catch(() => {})
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </form>
        <div className="p-12 border-l border-neutral-100 flex items-center justify-center grow bg-neutral-50">
          <PlanCard plan={planFormik.values} addons={null} addonsSubscribed={[]} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateBrandModal
