import React from 'react'
import {
  ResponsiveContainer,
  BarChart as RechartBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'

import { addUnits } from './utils'

export interface BarChartProps {
  query: any
  title: string
  name: string
  units?: string
  queryProps?: { startDate?: Date | null; endDate?: Date | null }
  positivity?: number
}

const BarChart = ({ title, units, query, queryProps }: BarChartProps) => {
  const { data, isLoading } = query(queryProps)

  if (!data?.data || isLoading) return null

  return (
    <div>
      <h2 className="mb-4">{title}</h2>
      <div className="flex space-x-12">
        <ResponsiveContainer width="70%" height={250}>
          <RechartBarChart data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" tickFormatter={new Intl.NumberFormat('en-US').format} />
            <YAxis yAxisId="right" orientation="right" tickFormatter={new Intl.NumberFormat('en-US').format} />
            <Tooltip formatter={(value: number) => addUnits(value, units)} />
            <Legend />
            <Bar dataKey={data.dataKeys[0].key} yAxisId="left" fill="#703cb5" name={data.dataKeys[0].name} />
            <Bar dataKey={data.dataKeys[1].key} yAxisId="right" fill="#1f9479" name={data.dataKeys[1].name} />
          </RechartBarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default BarChart
