import React, { ReactElement } from 'react'
import {
  ResponsiveContainer,
  LineChart as RechartLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts'

import TimeSummary from './TimeSummary'
import { addUnits } from './utils'

export interface LineChartProps {
  query: any
  title: string
  name: string
  units?: string
  queryProps?: { startDate?: Date | null; endDate?: Date | null }
  positivity?: number
}

const LineChart = ({ title, name, query, queryProps, units, positivity }: LineChartProps) => {
  const { data, isLoading } = query(queryProps)

  const Label = ({
    x,
    y,
    stroke,
    value,
    index,
  }: {
    x: number
    y: number
    stroke: string
    value: number
    index: number
  }) => {
    if (index < data.data.length - 4) return null as unknown as ReactElement<SVGElement>
    const deltaInPercent = data.data[index].deltaPercent

    const color = positivity === 1 ? (deltaInPercent > 0 ? 'green' : 'red') : deltaInPercent > 0 ? 'red' : 'green'

    return (
      <>
        <text x={x} y={y - 4} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          {addUnits(value, units)}
        </text>
        <text x={x} y={y + 20} dy={-4} fontSize={10} fill={color} textAnchor="middle">
          {deltaInPercent}%
        </text>
      </>
    )
  }

  if (!data?.data || isLoading) return null

  return (
    <div>
      <h2 className="mb-4">{title}</h2>
      <div className="flex space-x-12">
        <ResponsiveContainer width="70%" height={250}>
          <RechartLineChart data={data.data} margin={{ top: 5, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tickLine={false} className="text-xs" />
            <YAxis tickLine={false} className="text-xs" tickFormatter={new Intl.NumberFormat('en-US').format} />
            <Tooltip formatter={(value: number) => addUnits(value, units)} />
            <Legend />
            <Line name={name} type="monotone" dataKey="value" stroke="#00C49F" strokeWidth={2} label={Label} />
          </RechartLineChart>
        </ResponsiveContainer>
        <TimeSummary data={data} units={units} positivity={positivity} />
      </div>
    </div>
  )
}

export default LineChart
