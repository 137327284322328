import React from 'react'

import ArrowLeft from '../../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import ArrowRight from '../../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

export interface DatePickerHeaderProps {
  decreaseMonth: (() => void) | undefined
  increaseMonth: (() => void) | undefined
  date: Date
}

const DatePickerHeader = ({ decreaseMonth, increaseMonth, date }: DatePickerHeaderProps) => {
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()

  return (
    <div className="flex justify-between items-center px-4 font-semibold">
      <button onClick={decreaseMonth} aria-label="left" type="button">
        <ArrowLeft width={10} className="fill-neutral-800" />
      </button>
      <span>
        <span>{month}</span>
        <span className="ml-1">{year}</span>
      </span>
      <button onClick={increaseMonth} aria-label="right" type="button">
        <ArrowRight width={10} className="fill-neutral-800" />
      </button>
    </div>
  )
}

export default DatePickerHeader
