import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { CheckboxGroup, DatePickerInput, DateRangePickerInput } from '@packages/sk8/input'
import { ECommerce, PlanName, PrintFilesRequestStatus, SubscriptionStatus } from '@packages/types'
import { useFormik } from 'formik'
import React from 'react'

import { RouterInputs, RouterOutputs, trpc } from 'common/hooks/trpc'

type ScheduleJobInputs = RouterInputs['job']['queueTenants']

type Option<T> = { id: string; disabled?: boolean; label: string; value: T }

interface JobQueueFormProps {
  job: RouterOutputs['job']['list'][number]
  onSubmit: ReturnType<typeof trpc.job.queueTenants.useMutation>['mutate']
}

const statusOptions: Option<ScheduleJobInputs['statuses'][number]>[] = [
  { id: 'freeTrial', label: 'Free trial', value: SubscriptionStatus.FreeTrial },
  { id: 'active', label: 'Active', value: SubscriptionStatus.Active },
  { id: 'expired', label: 'Expired', value: SubscriptionStatus.Expired },
  { id: 'canceled', label: 'Canceled', value: SubscriptionStatus.Canceled },
  { id: 'requestCancel', label: 'Non-renewing', value: SubscriptionStatus.RequestCancel },
]

const planOptions: Option<ScheduleJobInputs['plans'][number]>[] = [
  { id: 'essential', label: 'Essential', value: PlanName.Essential },
  { id: 'startup', label: 'Startup', value: PlanName.Startup },
  { id: 'pro', label: 'Pro', value: PlanName.Pro },
  { id: 'business', label: 'Business', value: PlanName.Business },
  { id: 'enterprise', label: 'Enterprise', value: PlanName.Custom },
  { id: 'payAsYouGrow', label: 'Pay as you grow', value: PlanName.PayAsYouGrow },
  { id: 'scale', label: 'Scale', value: PlanName.Scale },
]

const onlineStoreOptions: Option<ScheduleJobInputs['onlineStores'][number]>[] = [
  { id: 'shopify', label: 'Shopify', value: ECommerce.Shopify },
  { id: 'prestashop', label: 'PrestaShop', value: ECommerce.Prestashop },
  { id: 'woocommerce', label: 'WooCommerce', value: ECommerce.Woocommerce },
  { id: 'wix', label: 'Wix', value: ECommerce.Wix },
  { id: 'custom', label: 'Custom Store', value: ECommerce.CustomStore },
]

const PrintFileStatus: Option<PrintFilesRequestStatus>[] = [
  { id: 'not-initiated', label: 'Not initiated', value: PrintFilesRequestStatus.NotInitiated },
  { id: 'error', label: 'Error', value: PrintFilesRequestStatus.Error },
  { id: 'pending', label: 'Pending', value: PrintFilesRequestStatus.Pending },
  { id: 'success', label: 'Success', value: PrintFilesRequestStatus.Success, disabled: true },
]

const JobQueueForm = ({ job, onSubmit }: JobQueueFormProps) => {
  const formik = useFormik<ScheduleJobInputs>({
    initialValues: {
      jobName: job.name,
      statuses: [],
      plans: [],
      onlineStores: [],
      data: { printFileStatus: [] },
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) =>
      onSubmit(values, {
        onSettled: () => {
          setSubmitting(false)
          resetForm()
        },
      }),
  })

  const hasAtLeastOneFilter =
    formik.values.statuses.length > 0 ||
    formik.values.plans.length > 0 ||
    formik.values.onlineStores.length > 0 ||
    formik.values.startDate ||
    formik.values.endDate ||
    formik.values.fromDate

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Card className="flex flex-1 flex-col">
        <div className="p-6">
          <Alert className="mb-8" variant="warning">
            <Alert.Body>
              <Alert.Details className="mb-1">
                Running a job for a large number of brands can make it run for several minutes, execute with precaution.
              </Alert.Details>
            </Alert.Body>
          </Alert>

          <div role="group" className="mb-8">
            <h4 className="pt-4 font-medium mb-4">Creation date</h4>
            <div className="flex">
              <DateRangePickerInput
                className="w-64"
                startDatePickerProps={{
                  onValueChange: (value?: Date | null) => formik.setFieldValue('startDate', value),
                  selected: formik.values.startDate ? new Date(formik.values.startDate) : undefined,
                  portalId: 'portal-d',
                }}
                endDatePickerProps={{
                  onValueChange: (value?: Date | null) => formik.setFieldValue('endDate', value),
                  selected: formik.values.endDate ? new Date(formik.values.endDate) : undefined,
                  portalId: 'portal-d',
                }}
              />
            </div>
          </div>

          <div className="mb-8 flex">
            <div role="group" className="flex flex-col flex-1">
              <h4 className="pt-4 font-medium mb-4">Plans</h4>
              <CheckboxGroup
                name="plans"
                options={planOptions.map(option => ({
                  ...option,
                  checked: formik.values.plans.includes(option.value),
                }))}
                onChange={e => {
                  formik.handleChange(e)
                  formik.setFieldTouched('plans', true, false)
                }}
              />
            </div>

            <div role="group" className="flex flex-col flex-1">
              <h4 className="pt-4 font-medium mb-4">Status</h4>
              <CheckboxGroup
                name="statuses"
                options={statusOptions.map(option => ({
                  ...option,
                  checked: formik.values.statuses.includes(option.value),
                }))}
                onChange={e => {
                  formik.handleChange(e)
                  formik.setFieldTouched('statuses', true, false)
                }}
              />
            </div>
          </div>

          <div className="mb-8 flex">
            <div role="group" className="flex flex-col flex-1">
              <h4 className="pt-4 font-medium mb-4">Store</h4>
              <CheckboxGroup
                name="onlineStores"
                options={onlineStoreOptions.map(option => ({
                  ...option,
                  checked: formik.values.onlineStores.includes(option.value),
                }))}
                onChange={e => {
                  formik.handleChange(e)
                  formik.setFieldTouched('onlineStores', true, false)
                }}
              />
            </div>

            {job.name === 'regeneratePrintFiles' && (
              <div role="group" className="flex flex-col flex-1">
                <h4 className="pt-4 font-medium mb-4">Print file status</h4>
                <CheckboxGroup
                  name="data.printFileStatus"
                  options={PrintFileStatus.map(option => ({
                    ...option,
                    checked: formik.values.data.printFileStatus.includes(option.value),
                  }))}
                  onChange={e => {
                    formik.handleChange(e)
                    formik.setFieldTouched('data.printFileStatus', true, false)
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex">
            {['regeneratePrintFiles', 'reconcileOrders'].includes(job.name) && (
              <div role="group" className="flex flex-col flex-1">
                <h4 className="pt-4 font-medium mb-4">Date from</h4>
                <DatePickerInput
                  placeholderText="All"
                  className="w-64"
                  autoComplete="off"
                  id="fromDate"
                  name="fromDate"
                  selected={formik.values.fromDate ? new Date(formik.values.fromDate) : undefined}
                  onValueChange={(value?: Date | null) => formik.setFieldValue('fromDate', value)}
                  hasError={formik.touched.fromDate && formik.errors.fromDate != null}
                />
              </div>
            )}
          </div>
        </div>
        <Card.Footer>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting || !hasAtLeastOneFilter}
            isLoading={formik.isSubmitting}
          >
            Add to queue
          </Button>
        </Card.Footer>
      </Card>
    </form>
  )
}

export default JobQueueForm
