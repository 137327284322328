import { Button } from '@packages/sk8/button'
import { Checkbox, Label } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { PaymentStrategy } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'

import useBrandService from './../hooks/useBrandService'
import type { Tenant, TenantUpdateData } from './../types/tenant'

export interface EditBrandModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
  brand: Tenant
}

const EditBrandModal = ({ brand, onClose, ...modalProps }: EditBrandModalProps) => {
  const { openToast } = useToast()
  const queryClient = useQueryClient()
  const brandService = useBrandService()
  const subscriptionService = useSubscriptionService()

  const { data: account } = useQuery(brandService.fetchAccount.queryKeys, () =>
    brandService.fetchAccount({ brandName: brand.name })
  )

  const formik = useFormik<TenantUpdateData>({
    initialValues: {
      isTest: brand.isTest,
      areQuotesAllowed: brand.areQuotesAllowed,
      areCustomStoresAllowed: account?.areCustomStoresAllowed || false,
    },
    onSubmit: values => updateBrand(values),
    enableReinitialize: true,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const { mutate: updateBrand } = useMutation((values: TenantUpdateData) => brandService.update(brand.name, values), {
    onSuccess: () => {
      openToast('Brand was successfully saved', ToastType.success)
      handleClose()
      queryClient.invalidateQueries(brandService.fetch.queryKeys)
    },
  })

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  if (!subscription) return null

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.CloseButton onClick={handleClose} />
        <Modal.Title>Edit brand {brand.name}</Modal.Title>

        <Modal.Details>
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-2">
              <Checkbox
                id="allow-quotes"
                name="areQuotesAllowed"
                checked={formik.values.areQuotesAllowed}
                onChange={e => formik.setFieldValue('areQuotesAllowed', e.target.checked)}
              />
              <Tooltip content="Allowing quotes will prevent a brand from being flagged with 0$ orders.">
                <Label htmlFor="allow-quotes">Allow quotes</Label>
              </Tooltip>
            </div>
            <div className="flex space-x-2">
              <Checkbox
                id="is-test"
                name="isTest"
                checked={formik.values.isTest}
                onChange={e => formik.setFieldValue('isTest', e.target.checked)}
              />
              <Tooltip content="Allow filtering of test brands.">
                <Label htmlFor="is-test">Test brand</Label>
              </Tooltip>
            </div>
            <div className="grow flex space-x-2">
              <Checkbox
                disabled={subscription.paymentStrategy !== PaymentStrategy.Manual}
                id="allow-custom-stores"
                name="areCustomStoresAllowed"
                checked={formik.values.areCustomStoresAllowed}
                onChange={e => formik.setFieldValue('areCustomStoresAllowed', e.target.checked)}
              />
              <Tooltip
                content={
                  subscription.paymentStrategy === PaymentStrategy.Manual
                    ? 'Allow custom stores, only available on custom plan'
                    : 'Only available on manual payment method'
                }
              >
                <Label htmlFor="allow-custom-stores">Allow custom stores</Label>
              </Tooltip>
            </div>
          </div>
        </Modal.Details>

        <Modal.Actions>
          <Button type="button" variant="default" className="px-4" onClick={handleClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            id="save-brand"
            type="submit"
            variant="primary"
            className="px-4"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            Save
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default EditBrandModal
