import React from 'react'

import AddCircle from '../../icons/bold/01-Interface Essential/43-Remove-Add/add-circle-alternate.svg'
import Remove from '../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../utils/classMerge'
import Button from '../button/Button'
import IconButton from '../button/IconButton'
import Popover from '../popover/Popover'
import usePopover from '../popover/usePopover'
import TagButton from './TagButton'

export interface TagsFilterProps {
  name: string
  filters: { name: string; text: React.ReactNode; className?: string; iconClassName?: string }[]
  activeFilters: string[]
  toggleFilter: (name: string) => void
  clearFilter: () => void
  disabled?: boolean
}

const TagsFilter = ({ name, filters, activeFilters, toggleFilter, clearFilter, disabled = false }: TagsFilterProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    offsetConfig: 12,
    autoUpdateOnAnimationFrame: true,
  })

  const activeStatusFilters = filters.filter(filter => activeFilters.includes(filter.name))
  const inactiveStatusFilters = filters.filter(filter => !activeFilters.includes(filter.name))

  const id = `add-${name.toLowerCase().split(' ').join('-')}-filters`

  return (
    <div
      className={classMerge(
        'h-7 rounded-xl border shadow-xs font-medium flex items-center overflow-hidden focus-within:border-primary-200 focus-within:ring border-neutral-100',
        {
          'border-dashed': activeFilters.length === 0,
          'border-neutral-50 border border-solid bg-neutral-50 text-neutral-200 fill-neutral-200': disabled,
        }
      )}
    >
      <Button
        variant="text"
        className="!text-xs mx-1.5 text-neutral-400"
        icon={<AddCircle className="w-3 h-3 fill-current" />}
        disabled={disabled}
        {...popover.referenceProps}
      >
        {name}
      </Button>
      {activeStatusFilters.length !== 0 && (
        <>
          <div className="h-full w-[1px] bg-neutral-75" />
          <div className="flex items-center space-x-1 bg-neutral-50 h-full pl-2 pr-0.5 flex-wrap">
            {activeStatusFilters.map(({ name, text, className, iconClassName }) => (
              <TagButton
                key={name}
                onClick={() => toggleFilter(name)}
                isSelected
                className={className}
                iconClassName={iconClassName}
                aria-label={`Active ${name} filter`}
                disabled={disabled}
              >
                {text}
              </TagButton>
            ))}
            <IconButton
              small
              aria-label="Clear tags filter"
              variant="subtle"
              Icon={Remove}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                clearFilter()
              }}
              disabled={disabled}
            />
          </div>
        </>
      )}

      <Popover isOpen={popover.isOpen} {...popover.floatingProps} aria-labelledby={id}>
        <div id={id} className="px-4 pt-4 mb-1 font-medium">
          Add {name.toLowerCase()} filters
        </div>
        <div className="w-[220px] flex flex-wrap pt-2 pb-2 px-2 max-h-96 overflow-y-auto">
          {inactiveStatusFilters.map(({ name, text, className }) => (
            <TagButton
              key={name}
              onClick={() => {
                toggleFilter(name)
                if (inactiveStatusFilters.length === 1) popover.close()
              }}
              containerClassName="mb-2 ml-2"
              className={className}
              isSelected={false}
              aria-label={`Set ${name} filter`}
            >
              {text}
            </TagButton>
          ))}
          {inactiveStatusFilters.length === 0 && (
            <div className="w-full flex justify-center text-neutral-300 mb-4">
              All {name.toLowerCase()} are selected
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default TagsFilter
