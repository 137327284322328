import { Button } from '@packages/sk8/button'
import { CheckboxGroup, Input, InputField, Label } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useFormik } from 'formik'
import { capitalize } from 'lodash'
import React from 'react'

import { jobMetadata } from 'cms/admin/jobs/utils'
import { RouterInputs, trpc } from 'common/hooks/trpc'

export interface QueueJobsModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
  tenant: string
}

const QueueJobsModal = ({ tenant, onClose, ...modalProps }: QueueJobsModalProps) => {
  const { openToast, openGenericErrorToast } = useToast()

  const { data: jobs } = trpc.job.list.useQuery(undefined, { initialData: [] })

  const { mutate: queueJobs } = trpc.job.queueMany.useMutation({
    onSuccess: () => {
      openToast(`Brand ${tenant} was successfully added to selected jobs queue!`, ToastType.success)
      onClose()
    },
    onError: () => {
      openGenericErrorToast(`Failed to queue brand ${tenant} for selected jobs`)
      onClose()
    },
  })

  const formik = useFormik({
    initialValues: {
      names: [] as RouterInputs['job']['queueMany']['names'],
      dangerousConfirms: [] as RouterInputs['job']['queueMany']['names'],
    },
    validate: values => {
      const errors: Record<string, string> = {}
      const dangerousJobs = values.names.filter(job => jobMetadata[job].isDangerous)
      for (let i = 0; i <= dangerousJobs.length; i++) {
        if (values.dangerousConfirms[i] !== dangerousJobs[i]) {
          errors[`dangerousConfirms.${i}`] = 'Invalid confirm'
        }
      }

      return errors
    },
    onSubmit: values => {
      queueJobs({ names: values.names, tenant })
    },
  })

  const requiresConfirmations = formik.values.names.filter(job => jobMetadata[job].isDangerous)

  return (
    <Modal size="medium" onBackdropClick={onClose} className="overflow-y-auto" {...modalProps}>
      <form onSubmit={formik.handleSubmit} noValidate>
        {formik.submitCount === 0 && (
          <>
            <Modal.CloseButton onClick={onClose} />
            <Modal.Title>Add brand {tenant} to jobs queue</Modal.Title>
            <Modal.Details>
              <div className="flex flex-col space-y-4">
                <CheckboxGroup
                  name="names"
                  options={jobs.map(job => ({
                    id: job.name,
                    label: `${capitalize(jobMetadata[job.name].type)}: ${jobMetadata[job.name].name}`,
                    value: job.name,
                    checked: formik.values.names.includes(job.name),
                  }))}
                  onChange={e => {
                    formik.handleChange(e)
                    formik.setFieldTouched('names', true, false)
                  }}
                />
              </div>
            </Modal.Details>

            <Modal.Actions>
              <Button type="button" variant="default" className="px-4" onClick={onClose} disabled={false}>
                Cancel
              </Button>
              <Button
                id="save-brand"
                type="submit"
                variant="primary"
                className="px-4"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Add to queue
              </Button>
            </Modal.Actions>
          </>
        )}
        {requiresConfirmations.length > 0 && formik.submitCount > 0 && (
          <>
            <Modal.CloseButton onClick={onClose} />
            <Modal.Title>Dangerous jobs</Modal.Title>
            <Modal.Details>
              <div className="font-medium">
                Some jobs that will be ran are dangerous, please fill the fields to confirm those jobs.
              </div>
              <div className="flex flex-col space-y-4 mt-4">
                {formik.values.names
                  .filter(job => jobMetadata[job].isDangerous)
                  .map((job, i) => {
                    return (
                      <InputField>
                        <Label htmlFor={`dangerousConfirms.${i}`}>
                          Enter "<span className="font-bold">{job}</span>" to confirm
                        </Label>
                        <Input
                          id={`dangerousConfirms.${i}`}
                          name={`dangerousConfirms.${i}`}
                          value={formik.values.dangerousConfirms[i]}
                          onChange={e => {
                            formik.handleChange(e)
                          }}
                        />
                      </InputField>
                    )
                  })}
              </div>
            </Modal.Details>

            <Modal.Actions>
              <Button
                type="button"
                variant="default"
                className="px-4"
                onClick={() => {
                  formik.resetForm({
                    values: {
                      names: [],
                      dangerousConfirms: [],
                    },
                  })
                }}
                disabled={false}
              >
                Cancel
              </Button>
              <Button
                id="save-brand"
                type="button"
                variant="primary"
                className="px-4"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                onClick={() => {
                  formik.submitForm()
                }}
              >
                Add to queue
              </Button>
            </Modal.Actions>
          </>
        )}
      </form>
    </Modal>
  )
}

export default QueueJobsModal
