import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import React from 'react'

interface DeleteAccessModalProps extends React.ComponentProps<typeof Modal> {
  isLoading?: boolean
  onDelete: () => void
  onClose: () => void
}

const DeleteAccessModal = ({
  isLoading,
  onDelete,
  onClose,
  ...modalProps
}: Omit<DeleteAccessModalProps, 'children'>) => {
  return (
    <Modal onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Delete brand access</Modal.Title>

      <Modal.Details>Are you sure you want to delete this brand access? This action cannot be undone.</Modal.Details>
      <Modal.Actions>
        <Button type="button" variant="default" className="px-4" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="error"
          className="px-4"
          onClick={onDelete}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteAccessModal
