import { Button } from '@packages/sk8/button'
import { HelperText, InputField, Label, Select } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { AdminUserRole } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as yup from 'yup'

import { NetworkError } from 'common/api/types/error'
import { trpc } from 'common/hooks/trpc'

import useBrandAccessService, { CreateBrandAccessResponse } from './../hooks/useBrandAccessService'
import type { CreateBrandAccessFormValues } from './../types/form'

const formValidation = yup.object().shape({ user: yup.string().required('Please select a user') })

export interface GrantAdminAccessModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  tenant: string
  onClose: () => void
}

const GrantAdminAccessModal = ({ tenant, onClose, ...modalProps }: GrantAdminAccessModalProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const brandAccessService = useBrandAccessService()

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const { data: adminUsers = [] } = trpc.adminUser.list.useQuery({ role: AdminUserRole.MczrAdmin })

  const { mutate: createBrandAccess } = useMutation<
    CreateBrandAccessResponse,
    NetworkError,
    CreateBrandAccessFormValues
  >(values => brandAccessService.create(values), {
    onSuccess: () => {
      openToast('Brand access was successful granted', ToastType.success)
      queryClient.invalidateQueries(brandAccessService.fetchAll.queryKeys)
      handleClose()
    },
    onError: error => {
      if (error.status === 409) {
        openToast(`This user has already access to ${tenant}!`, ToastType.warning)
      } else {
        openGenericErrorToast('There was an error granting admin access.')
      }
    },
  })

  const formik = useFormik<CreateBrandAccessFormValues>({
    initialValues: { tenant: '', user: '' },
    onSubmit: (values, { setSubmitting }) => createBrandAccess(values, { onSettled: () => setSubmitting(false) }),
    validationSchema: formValidation,
  })

  useEffect(() => {
    formik.setFieldValue('tenant', tenant)
  }, [tenant])

  const adminUsersOptions = adminUsers.map(user => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} (${user.email})`,
  }))

  return (
    <Modal className="!w-[400px]" onBackdropClick={handleClose} {...modalProps}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.CloseButton onClick={handleClose} />
        <Modal.Title>Give admin access to {tenant}’s account?</Modal.Title>

        <Modal.Details>
          <div className="flex flex-col space-y-6 text-neutral-900">
            <InputField className="flex-1">
              <Label htmlFor="user">Admin user</Label>
              <Select
                id="user"
                name="user"
                placeholder="Admin user"
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={adminUsersOptions.find(option => option.value === formik.values.user) || null}
                options={adminUsersOptions}
                onChange={option => formik.setFieldValue('user', option?.value)}
                onBlur={formik.handleBlur}
                hasError={formik.touched.tenant && formik.errors.tenant != null}
              />
              {formik.touched.tenant && formik.errors.tenant != null && (
                <HelperText hasError>{formik.errors.tenant}</HelperText>
              )}
            </InputField>
          </div>
        </Modal.Details>

        <Modal.Actions>
          <Button type="button" variant="default" className="px-4" onClick={handleClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="px-4"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Grant
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default GrantAdminAccessModal
