import React from 'react'

import Calendar from '../../../icons/bold/01-Interface Essential/21-Date-Calendar/calendar.svg'
import Remove from '../../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../../utils/classMerge'

export interface DatePickerIconProps {
  showRemoveIcon?: boolean
  onCalendarClick: () => void
  onClearDate: () => void
  disabled?: boolean
}

const DatePickerIcon = ({ showRemoveIcon, onClearDate, onCalendarClick, disabled }: DatePickerIconProps) => {
  return showRemoveIcon ? (
    <button type="button" onClick={onClearDate} aria-label="clear" disabled={disabled}>
      <Remove
        width={14}
        height={14}
        className={classMerge('p-0.5 fill-neutral-300', { 'hover:fill-neutral-900': !disabled })}
      />
    </button>
  ) : (
    <button type="button" onClick={onCalendarClick} aria-label="calendar" disabled={disabled}>
      <Calendar
        width={14}
        height={14}
        className={classMerge('fill-neutral-300', { 'hover:fill-neutral-900': !disabled })}
      />
    </button>
  )
}

export default DatePickerIcon
