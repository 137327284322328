import React from 'react'

import classMerge from '../../utils/classMerge'
import Checkbox, { CheckboxProps } from './Checkbox'

export interface CheckboxGroupProps {
  className?: string
  disabled?: boolean
  name: string
  options: Array<CheckboxProps & { label: React.ReactNode }>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  horizontal?: boolean
}

const CheckboxGroup = ({ className, name, disabled, horizontal, onChange, options }: CheckboxGroupProps) => (
  <div
    className={classMerge(
      'flex',
      { 'flex-col': !horizontal, 'space-y-3': !horizontal, 'space-x-6': horizontal, 'text-neutral-200': disabled },
      className
    )}
    role="group"
  >
    {options.map(option => (
      <div key={option.id} className={classMerge('flex items-center font-input whitespace-nowrap', option.className)}>
        <Checkbox {...option} onChange={onChange} name={name} disabled={disabled || option.disabled} />
        <label
          className={classMerge('ml-2 overflow-hidden whitespace-nowrap text-ellipsis', {
            'text-neutral-200': disabled || option.disabled,
            'cursor-pointer': !disabled && !option.disabled,
          })}
          htmlFor={option.id}
          title={typeof option.label === 'string' ? option.label : undefined}
        >
          {option.label}
        </label>
      </div>
    ))}
  </div>
)

export default CheckboxGroup
