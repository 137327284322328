import 'utils/publicPath'
import 'utils/yupNumberSchema'

import { AdminUserRole } from '@packages/types'
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { httpBatchLink } from '@trpc/client'
import { createFlagsmithInstance } from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import BrandAccesses from 'cms/admin/accesses/components/BrandAccesses'
import Analytics from 'cms/admin/analytics/components/Analytics'
import Brands from 'cms/admin/brands/components/Brands'
import ImpersonateBrand from 'cms/admin/components/ImpersonateBrand'
import Job from 'cms/admin/jobs/components/Job'
import Jobs from 'cms/admin/jobs/components/Jobs'
import AdminUsers from 'cms/admin/users/components/AdminUsers'
import CreateAdminUser from 'cms/admin/users/components/CreateAdminUser'
import EditAdminUser from 'cms/admin/users/components/EditAdminUser'
import LoggedInRoutes from 'cms/auth/components/LoggedInRoutes'
import Login from 'cms/auth/components/Login'
import ProtectedRoute from 'cms/auth/components/ProtectedRoute'
import BrandRoutes from 'cms/layout/BrandRoutes'
import { trpc } from 'common/hooks/trpc'
import getAPIUrl from 'utils/getAPIUrl'
import getXMCZRHeader from 'utils/getXMCZRHeader'

import store from './store'

import 'styles/app.css'

window.baseURL = '/'
window.USER_INFOS_URL = '/admin/users/infos'
window.LOGIN_URL = '/admin/login'
window.FORGOT_PASSWORD_URL = '/admin/users/login/forgot'
window.CHANGE_PASSWORD_URL = '/admin/users/login/password-reset'
window.ACCEPT_INVITE_URL = '/admin/users/login/accept-invite'
window.DOCUMENT_DOMAIN = window.location.host.includes(DOMAIN) ? DOMAIN : ALTERNATE_DOMAIN

if (CURRENT_ENV === 'development') {
  window.DOCUMENT_DOMAIN = `${window.DOCUMENT_DOMAIN}:${window.location.port}`
}

const flagsmith = createFlagsmithInstance()

class App {
  init() {
    // eslint-disable-next-line no-restricted-syntax
    console.log(
      `%c·kıckflıp 2.0 🛹`,
      'color:#FFFFFF; background:#0021f5; font-size:1.5rem; padding:0.5rem; margin: 1rem auto; border: 2px solid #0021f5; border-radius: 8px;font-weight: bold; text-shadow: 1px 1px 1px #000000;'
    )

    const root = createRoot(document.getElementById('root')!)
    const queryClient = new QueryClient()
    const stripe = loadStripe(STRIPE_KEY)

    const trpcClient = trpc.createClient({
      links: [
        httpBatchLink({
          url: `${getAPIUrl()}/trpc`,
          headers: getXMCZRHeader,
        }),
      ],
    })

    root.render(
      <FlagsmithProvider options={{ environmentID: FLAGSMITH_PUBLIC_KEY }} flagsmith={flagsmith}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <StripeElementsProvider stripe={stripe}>
              <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <BrowserRouter>
                  <Toaster position="top-center" containerStyle={{ inset: '56px' }} />
                  <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <Switch>
                      <Route path="/login" component={Login} />
                      <LoggedInRoutes>
                        <Switch>
                          <Redirect exact from="/" to="/brands" />
                          <Redirect exact from="/dashboard" to="/brands" />
                          <Route exact path="/brands" component={Brands} />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            exact
                            path="/analytics"
                            component={Analytics}
                          />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            exact
                            path="/users"
                            component={AdminUsers}
                          />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            exact
                            path="/users/create"
                            component={CreateAdminUser}
                          />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            path="/users/:id"
                            component={EditAdminUser}
                          />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            exact
                            path="/accesses"
                            component={BrandAccesses}
                          />
                          <ProtectedRoute allowedRoles={[AdminUserRole.Master]} exact path="/jobs" component={Jobs} />
                          <ProtectedRoute
                            allowedRoles={[AdminUserRole.Master]}
                            exact
                            path="/jobs/:jobName"
                            component={Job}
                          />
                          <ProtectedRoute path="/brands/:brandName" allowedRoles={[AdminUserRole.Master]}>
                            {({ match }) => (
                              <ImpersonateBrand brand={match!.params.brandName!}>
                                <BrandRoutes />
                              </ImpersonateBrand>
                            )}
                          </ProtectedRoute>
                        </Switch>
                      </LoggedInRoutes>
                    </Switch>
                  </QueryParamProvider>
                </BrowserRouter>
              </trpc.Provider>
            </StripeElementsProvider>
          </Provider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelProps={{ className: 'print:!hidden' }}
            toggleButtonProps={{ className: 'print:!hidden' }}
          />
        </QueryClientProvider>
      </FlagsmithProvider>
    )
  }
}

const app = new App()

app.init()

export default app
