import { DatesFilter, FilterContainer, FilterList } from '@packages/sk8/filter'
import React from 'react'
import { DateParam } from 'use-query-params'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import useDataTableQuery from 'common/hooks/dataTable/persistence/useDataTableQuery'
import useDataTable from 'common/hooks/dataTable/useDataTable'
import useTableDateFilters from 'common/hooks/dataTable/useTableDateFilters'
import { trpc } from 'common/hooks/trpc'

import BarChart from './BiaxialBarChart'
import LineChart from './LineChart'

import './Analytics.css'

const datas = [
  {
    key: 'best-sellers',
    query: trpc.analytics.getBestSellers.useQuery,
    title: 'Best sellers',
    name: 'Tx fees/month',
    Component: BarChart,
    units: '$',
    positivity: 1,
  },
  {
    key: 'transaction-fees',
    query: trpc.analytics.getTransactionFeesPerMonth.useQuery,
    title: 'Transaction fees',
    name: 'Tx fees/month',
    Component: LineChart,
    units: '$',
    positivity: 1,
  },
  {
    key: 'mrr',
    query: trpc.analytics.getRecurringRevenuePerMonth.useQuery,
    title: 'Recurring revenue',
    name: 'Recurring revenue/month',
    Component: LineChart,
    units: '$',
    positivity: 1,
  },
  {
    key: 'total-revenue',
    query: trpc.analytics.getTotalRevenuePerMonth.useQuery,
    title: 'Total revenue',
    name: 'Total revenue/month',
    Component: LineChart,
    units: '$',
    positivity: 1,
  },
  {
    key: 'transaction-fees-per-client',
    query: trpc.analytics.getTransactionFeesPerMonthPerClient.useQuery,
    title: 'Transaction fees per client',
    name: 'Tx fees per client/month',
    Component: LineChart,
    units: '$',
    positivity: 1,
  },
  {
    key: 'time-to-transaction',
    query: trpc.analytics.getTimeToTransactionPerMonth.useQuery,
    title: 'Time to transaction',
    name: 'Avg. time to tx',
    Component: LineChart,
    units: 'days',
    positivity: -1,
  },
  {
    key: 'free-trials',
    name: 'Free trials/month',
    query: trpc.analytics.getFreeTrialsPerMonth.useQuery,
    title: 'Free trials',
    Component: LineChart,
    positivity: 1,
  },
  {
    key: 'sales',
    name: 'Sales/month',
    query: trpc.analytics.getSalesPerMonth.useQuery,
    title: 'Sales',
    Component: LineChart,
    positivity: 1,
    units: '$',
  },
]

const AnalyticsPage = () => {
  const persistence = useDataTableQuery(
    {
      startDate: undefined,
      endDate: undefined,
    },
    {
      startDate: DateParam,
      endDate: DateParam,
    }
  )

  const dataTable = useDataTable(persistence, [useTableDateFilters({})])

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <h1>Analytics</h1>
        </Page.Header>
        <FilterContainer>
          <FilterList>
            <DatesFilter
              name="Time range"
              startDate={dataTable.startDate}
              endDate={dataTable.endDate}
              setStartDate={date => dataTable.setStartDate(date ? date : undefined)}
              setEndDate={date => dataTable.setEndDate(date ? date : undefined)}
            />
          </FilterList>
        </FilterContainer>
        <div className="flex flex-col space-y-4 mt-8">
          {datas.map(({ Component, key, query, title, name, units, positivity }) => (
            <Component
              key={key}
              query={query}
              title={title}
              name={name}
              queryProps={{ startDate: dataTable.startDate, endDate: dataTable.endDate }}
              units={units}
              positivity={positivity}
            />
          ))}
        </div>
      </Page>
    </main>
  )
}

export default AnalyticsPage
