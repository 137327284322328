import { Brand } from '@packages/types'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import type { Paginated } from 'common/api/types/pagination'

import type { BrandsSortKeys } from './../types/dataTable'
import type { CreateBrandFormValues } from './../types/form'
import type { Tenant, TenantUpdateData } from './../types/tenant'

export type VerifyBrandNameParams = { brandName: string }
export type VerifyBrandNameResponse = { available: boolean }

export type FetchBrandsParams = {
  plans?: string[]
  eCommerceTypes?: string[]
  status?: string[]
  various?: string[]
  sortOrder?: 'descending' | 'ascending'
  sortKey?: BrandsSortKeys
  count?: number
  page?: number
  filter?: string
  endDate?: Date | null
  startDate?: Date | null
}
export type FetchResponse = Paginated<Tenant>

export type FetchSubscriptionParams = { brandName: string }

const useBrandService = () => {
  return {
    fetch: createQuery(['brands'], async (params: FetchBrandsParams) => {
      const { data } = await apiClient.get<FetchResponse>(`/admin/brands`, { params })
      return data
    }),
    create: async (values: CreateBrandFormValues) => {
      const { data } = await apiClient.post<Tenant>('/admin/brands', values)
      return data
    },
    update: async (brandName: string, payload: TenantUpdateData) => {
      const { data } = await apiClient.post<Tenant>(`/admin/brands/${brandName}`, payload)
      return data
    },
    fetchAccount: createQuery(['fetch-account'], async ({ brandName }: FetchSubscriptionParams) => {
      const { data } = await apiClient.get<Brand>(`/brands/${brandName}/brands/account`)
      return data
    }),
    verifyBrandName: async ({ brandName }: VerifyBrandNameParams) => {
      const { data } = await apiClient.get<VerifyBrandNameResponse>(`/admin/brands/available/${brandName}`)
      return data
    },
  }
}

export default useBrandService
