import React from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import classMerge from '../../utils/classMerge'
import ModalActions from './ModalActions'
import ModalActionsMessage from './ModalActionsMessage'
import ModalCloseButton from './ModalCloseButton'
import ModalDetails from './ModalDetails'
import ModalDivider from './ModalDivider'
import ModalImage from './ModalImage'
import ModalStepperFooter from './ModalStepperFooter'
import ModalStepperStartFooter from './ModalStepperStartFooter'
import ModalTitle from './ModalTitle'

export interface ModalProps {
  children: React.ReactNode
  className?: string
  size?: 'small' | 'medium' | 'large'
  onBackdropClick?: () => void
  isOpen?: boolean
  unmount: () => void
  overModal?: boolean
  backdropClassName?: string
}

const Modal = ({
  children,
  className,
  size = 'medium',
  onBackdropClick,
  isOpen,
  unmount,
  overModal,
  backdropClassName,
  ...rest
}: ModalProps) =>
  createPortal(
    <RemoveScroll>
      <div
        onClick={onBackdropClick}
        onAnimationEnd={() => {
          if (!isOpen) unmount()
        }}
        className={classMerge(
          'fixed top-0 left-0 flex h-screen w-full bg-neutral-800 bg-opacity-20 justify-center items-center z-50',
          {
            'animate-modal-backdrop-show': isOpen,
            'animate-modal-backdrop-hide opacity-0': !isOpen,
            'z-[100]': overModal,
          },
          backdropClassName
        )}
        role="presentation"
        aria-label="backdrop"
      />
      <div
        role="dialog"
        className={classMerge(
          'fixed top-0 left-0 right-0 bottom-0 m-auto flex flex-col rounded-lg shadow-xs max-h-[90vh] h-fit overflow-hidden bg-white z-50',
          {
            'animate-modal-show': isOpen,
            'animate-modal-hide opacity-0': !isOpen,
            'w-full md:w-[300px]': size === 'small',
            'w-full md:w-[600px]': size === 'medium',
            'w-full md:w-[1000px]': size === 'large',
          },
          className
        )}
        onClick={e => e.stopPropagation()}
        {...rest}
      >
        {children}
      </div>
    </RemoveScroll>,
    document.body
  )

Modal.Title = ModalTitle
Modal.CloseButton = ModalCloseButton
Modal.Details = ModalDetails
Modal.Actions = ModalActions
Modal.ActionsMessage = ModalActionsMessage
Modal.Stepper = ModalStepperFooter
Modal.StepperStart = ModalStepperStartFooter
Modal.Image = ModalImage
Modal.Divider = ModalDivider

export default Modal
