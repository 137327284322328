import React, { MutableRefObject, useRef, useState } from 'react'
import { TableVirtuosoProps, VirtuosoHandle } from 'react-virtuoso'

import { ScrollToTopButtonProps } from '../button/ScrollToTopButton'
import { FilterContainerProps } from '../filter/FilterContainer'

type PartialTableVirtuoso = Pick<TableVirtuosoProps<any, any>, 'atTopStateChange' | 'isScrolling'> & {
  ref: React.RefObject<VirtuosoHandle>
}

export interface StickyFilterContainerProps extends FilterContainerProps {
  isScrollAtTop: boolean
}

export type useInfiniteTableAddonsReturn = {
  tableProps: PartialTableVirtuoso
  stickyFilterContainerProps: StickyFilterContainerProps
  scrollToTopButtonProps: ScrollToTopButtonProps
}

const useInfiniteTableAddons = ({
  scrollParentRef,
  useWindowScroll = false,
}: {
  scrollParentRef?: MutableRefObject<HTMLElement | null>
  useWindowScroll?: boolean
} = {}): useInfiniteTableAddonsReturn => {
  const ref = useRef<VirtuosoHandle>(null)

  const [isScrollAtTop, setIsScrollAtTop] = useState(true)

  return {
    tableProps: {
      ref,
      atTopStateChange: setIsScrollAtTop,
    },
    stickyFilterContainerProps: {
      isScrollAtTop,
    },
    scrollToTopButtonProps: {
      isVisible: !isScrollAtTop,
      onClick: () => {
        if (useWindowScroll)
          return window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })

        const scrollRef = scrollParentRef ?? ref
        scrollRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    },
  }
}

export default useInfiniteTableAddons
