import React from 'react'

import BetaIcon from '../../icons/custom/Flask-Half--Streamline-Core.svg'
import classMerge from '../../utils/classMerge'
import BetaToolTip from './BetaToolTip'

export interface SmallBetaTagProps {
  disableTooltip?: boolean
  className?: string
}

const SmallBetaTag = ({ className, disableTooltip = false }: SmallBetaTagProps) => {
  return (
    <BetaToolTip disableTooltip={disableTooltip}>
      <BetaIcon className={classMerge(className, 'w-3 h-3 fill-tertiary-green-300')} />
    </BetaToolTip>
  )
}

export default SmallBetaTag
