import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import { trpc } from 'common/hooks/trpc'

import { JobStatus, JobType, jobMetadata } from '../utils'

const typeTags = {
  [JobType.FeatureFlag]: { text: 'Feature flags', className: 'bg-[#6630FF] text-white' },
  [JobType.Indexing]: { text: 'Indexing', className: 'bg-neutral-75' },
  [JobType.Hubspot]: { text: 'Hubspot', className: 'bg-[#FE5C35] text-white' },
  [JobType.Assets]: { text: 'Assets', className: 'bg-success-dark text-white' },
  [JobType.Reconciliation]: { text: 'Reconciliation', className: 'bg-primary-400 text-white' },
  [JobType.Other]: { text: 'Other', className: 'bg-neutral-600 text-white' },
  [JobType.Shopify]: { text: 'Shopify', className: 'bg-[#96bf48] text-white' },
}

const statusTags = {
  [JobStatus.Waiting]: { text: 'Waiting', className: 'bg-warning-light text-secondary-orange-700' },
  [JobStatus.Running]: { text: 'Running', className: 'bg-tertiary-green-100 text-tertiary-green-700' },
}

const Jobs = () => {
  const history = useHistory()
  const { data: jobs, isLoading, isFetching } = trpc.job.list.useQuery(undefined, { initialData: [] })

  return (
    <main>
      <Header />
      <SideMenu />

      <Page>
        <Page.Header>
          <h1>Jobs</h1>
        </Page.Header>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-4/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12">
                <span>Type</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12">
                <span>Status</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12">
                <span>Running brands</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12">
                <span>Queued brands</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-[48px] min-w-[48px]" />
            </Table.HeaderRow>
          </Table.Header>

          {isLoading && <Table.BodyLoader numberOfColumns={5} numberOfRows={9} />}

          {!isLoading && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={5} />}
              {jobs.map(job => {
                const { name, type } = jobMetadata[job.name]

                const isRunning = job.runningTenants.length > 0 || job.queuedTenants.length > 0
                const typeTag = typeTags[type]
                const statusTag = statusTags[isRunning ? JobStatus.Running : JobStatus.Waiting]

                return (
                  <Table.Row key={job.name} className="cursor-pointer" onClick={() => history.push(`jobs/${job.name}`)}>
                    <Table.Cell>
                      <div className="min-w-fit">{name}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">
                        <Tag className={classNames('rounded', typeTag.className)}>{typeTag.text}</Tag>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">
                        <Tag className={classNames('rounded', statusTag.className)}>{statusTag.text}</Tag>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">{job.runningTenants.length}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">{job.queuedTenants.length}</div>
                    </Table.Cell>
                    <Table.Cell width={48}>
                      <div className="flex justify-center"></div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          )}
        </Table>
      </Page>
    </main>
  )
}

export default Jobs
