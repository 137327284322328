import { createFlagsmithInstance } from 'flagsmith'

let flagsmith = createFlagsmithInstance()

flagsmith.init({
  environmentID: FLAGSMITH_PUBLIC_KEY,
  preventFetch: true,
  defaultFlags: window.initialData?.flags,
})

export const overrideFlagsmithInstance = (instance: typeof flagsmith) => {
  flagsmith = instance
}

export default () => {
  return flagsmith
}
