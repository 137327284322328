import React, { forwardRef } from 'react'

import classMerge from '../../utils/classMerge'
import Button, { ButtonProps } from './Button'

export interface IconButtonProps extends ButtonProps {
  Icon: React.ElementType
  smallIcon?: boolean
  small?: boolean
  iconClassName?: string
  iconStyle?: React.CSSProperties
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ Icon, small = false, smallIcon = false, className, iconClassName = '', iconStyle, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className={classMerge(className, { 'px-1 !rounded': small, 'h-6': small })}>
        <Icon
          className={classMerge('w-3.5 fill-current text-current', iconClassName, {
            'p-0.5': smallIcon || small,
          })}
          style={iconStyle}
        />
      </Button>
    )
  }
)

export default IconButton
