import { NumberFilter } from '@packages/sk8/filter'
import { useModal } from '@packages/sk8/modal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { ArrayParam, DateParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import GrantAdminAccessModal from 'cms/admin/accesses/components/GrantAdminAccessModal'
import QueueJobsModal from 'cms/admin/accesses/components/QueueJobsModal'
import useBrandAccessService from 'cms/admin/accesses/hooks/useBrandAccessService'
import ImpersonateBrand from 'cms/admin/components/ImpersonateBrand'
import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import ExtendFreeTrialModal from 'cms/subscription/components/modals/ExtendFreeTrialModal'
import useDataTableQuery from 'common/hooks/dataTable/persistence/useDataTableQuery'
import useDataTable from 'common/hooks/dataTable/useDataTable'
import useTableDateFilters from 'common/hooks/dataTable/useTableDateFilters'
import useTableFilters from 'common/hooks/dataTable/useTableFilters'
import useTablePagination from 'common/hooks/dataTable/useTablePagination'
import useTableSearch from 'common/hooks/dataTable/useTableSearch'
import useTableSort from 'common/hooks/dataTable/useTableSort'
import { trpc } from 'common/hooks/trpc'
import TenantContext from 'common/tenant/TenantContext'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import { getBrandAppUrl } from 'utils/getAppUrl'
import getCallbackUrl from 'utils/getCallbackUrl'

import useBrandService from './../hooks/useBrandService'
import type { BrandsFilters, BrandsSortKeys } from './../types/dataTable'
import type { TenantUpdateData } from './../types/tenant'
import BrandsFilterList from './BrandsFilterList'
import BrandsHeader from './BrandsHeader'
import BrandsTable from './BrandsTable'
import CreateBrandModal from './CreateBrandModal'
import EditBrandModal from './EditBrandModal'

const BrandsPage = () => {
  const brandService = useBrandService()
  const brandAccessService = useBrandAccessService()
  const [adminAccessModalTenant, setAdminAccessModalTenant] = useState<string | undefined>()
  const [freeTrialModalTenant, setFreeTrialModalTenant] = useState<string | undefined>()
  const [editedBrandName, setEditedBrandName] = useState<string | undefined>()
  const [queueJobsBrand, setQueueJobsBrand] = useState<string | undefined>()
  const location = useLocation()
  const createBrandModal = useModal()
  const editBrandModal = useModal()
  const grantAdminAccessModal = useModal()
  const extendFreeTrialModal = useModal()
  const queueJobsModal = useModal()
  const history = useHistory()

  const queryClient = useQueryClient()

  const { currentUser, isMasterUser } = useCurrentUser()

  const persistence = useDataTableQuery(
    {
      plans: [],
      addons: [],
      eCommerceTypes: [],
      status: [],
      conversionRates: [],
      various: [],
      startDate: undefined,
      endDate: undefined,
      collectionSize: 0,
      resultSize: 0,
      lastIndex: 0,
      count: 25,
      filter: '',
      sortKey: undefined,
      sortOrder: undefined,
    },
    {
      startDate: DateParam,
      endDate: DateParam,
      count: withDefault(NumberParam, 25),
      lastIndex: withDefault(NumberParam, 0),
      filter: withDefault(StringParam, ''),
      sortKey: StringParam,
      sortOrder: StringParam,
      plans: withDefault(ArrayParam, []),
      addons: withDefault(ArrayParam, []),
      eCommerceTypes: withDefault(ArrayParam, []),
      status: withDefault(ArrayParam, []),
      conversionRates: withDefault(ArrayParam, []),
      various: withDefault(ArrayParam, []),
      //@ts-expect-error we need to return object from encode
      orders: withDefault(NumberFilter.URLParam, undefined),
      //@ts-expect-error we need to return object from encode
      designs: withDefault(NumberFilter.URLParam, undefined),
    }
  )

  const goToFirstPage = () => persistence.setState(state => ({ ...state, lastIndex: 0 }))

  const dataTable = useDataTable(persistence, [
    useTableSearch({ onChange: goToFirstPage }),
    useTableSort<BrandsSortKeys>({ onChange: goToFirstPage }),
    useTablePagination(),
    useTableFilters<BrandsFilters>({ onChange: goToFirstPage }),
    useTableDateFilters({ onChange: goToFirstPage }),
  ])

  const { data, isLoading, isFetching } = useQuery(
    [...brandService.fetch.queryKeys, persistence.state],
    () => brandService.fetch(persistence.state),
    {
      keepPreviousData: true,
      onSuccess: result => {
        dataTable.setCollectionSize(result.pagination.collectionSize)
        dataTable.setResultSize(result.pagination.resultSize)
      },
    }
  )

  const { data: userAccesses } = useQuery(
    brandAccessService.fetchAllForUser.queryKeys,
    () => brandAccessService.fetchAllForUser(),
    { enabled: !!currentUser?.id }
  )

  const { mutate: updateTenant } = useMutation(
    ({ name, ...rest }: TenantUpdateData & { name: string }) => brandService.update(name, rest),
    {
      onSuccess: async () => queryClient.invalidateQueries([...brandService.fetch.queryKeys, persistence.state]),
    }
  )

  const { mutate: impersonateBrandAdmin } = trpc.adminUser.impersonateBrandAdmin.useMutation({
    onSuccess: async (token, tenant) => {
      const brandAppUrl = getBrandAppUrl(tenant)
      const redirectUrl = `${brandAppUrl}/admin/products`
      const callbackUrl = getCallbackUrl(brandAppUrl, token, redirectUrl)

      window.open(callbackUrl, '_blank')
    },
  })

  const getAccessLevelForTenant = (tenantName: string): 'master' | 'admin' | null => {
    if (isMasterUser) return 'master'
    return userAccesses?.some(access => access.tenant === tenantName) ? 'admin' : null
  }

  const handleBrandClick = async (tenantName: string) => {
    if (isMasterUser) {
      history.push(`/brands/${tenantName}/products`)
    } else if (getAccessLevelForTenant(tenantName)) {
      impersonateBrandAdmin(tenantName)
    }
  }

  const handleGrantAdminAccessClick = (tenantName: string) => {
    setAdminAccessModalTenant(tenantName)
    grantAdminAccessModal.open()
  }

  const handleExtendFreeTrialClick = (tenantName: string) => {
    setFreeTrialModalTenant(tenantName)
    extendFreeTrialModal.open()
  }

  const handleEditClick = (tenantName: string) => {
    setEditedBrandName(tenantName)
    editBrandModal.open()
  }

  const handleQueueJobs = (tenantName: string) => {
    setQueueJobsBrand(tenantName)
    queueJobsModal.open()
  }

  const handleToggleFraudRisk = (tenantName: string) => {
    const tenant = data?.results.find(brand => brand.name === tenantName)
    if (!tenant) return

    if (tenant.hasFraudRisk) {
      updateTenant({ name: tenantName, manualFraudRiskFlag: true, 'indexing.hasFraudRisk': false })
    } else {
      updateTenant({ name: tenantName, manualFraudRiskFlag: true, 'indexing.hasFraudRisk': true })
    }
  }

  const editedBrand = data?.results.find(brand => brand.name === editedBrandName)

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <BrandsHeader isMasterUser={isMasterUser} onCreateBrand={createBrandModal.open} />
        <BrandsFilterList hasAppliedFilters={!!location.search} clearAll={persistence.reset} {...dataTable} />
        <BrandsTable
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          getAccessLevelForTenant={getAccessLevelForTenant}
          brandsActions={{
            click: handleBrandClick,
            extendFreeTrial: handleExtendFreeTrialClick,
            grantAdminAccess: handleGrantAdminAccessClick,
            queueJobs: handleQueueJobs,
            edit: handleEditClick,
            toggleFraudRisk: handleToggleFraudRisk,
          }}
          onClearFilters={persistence.reset}
          {...dataTable}
        />
        {createBrandModal.isVisible && (
          <CreateBrandModal onClose={createBrandModal.close} {...createBrandModal.modalProps} />
        )}
        {editBrandModal.isVisible && editedBrand && (
          <TenantContext.Provider value={editedBrand.name}>
            <EditBrandModal
              brand={editedBrand}
              onClose={() => {
                editBrandModal.close()
                setEditedBrandName(undefined)
              }}
              {...editBrandModal.modalProps}
            />
          </TenantContext.Provider>
        )}
        {freeTrialModalTenant && extendFreeTrialModal.isVisible && (
          <ImpersonateBrand brand={freeTrialModalTenant}>
            <ExtendFreeTrialModal
              onClose={() => {
                extendFreeTrialModal.close()
                setFreeTrialModalTenant(undefined)
              }}
              {...extendFreeTrialModal.modalProps}
            />
          </ImpersonateBrand>
        )}
        {adminAccessModalTenant && grantAdminAccessModal.isVisible && (
          <GrantAdminAccessModal
            tenant={adminAccessModalTenant}
            onClose={grantAdminAccessModal.close}
            {...grantAdminAccessModal.modalProps}
          />
        )}
        {queueJobsBrand && queueJobsModal.isVisible && (
          <QueueJobsModal
            tenant={queueJobsBrand}
            onClose={() => {
              queueJobsModal.close()
              setQueueJobsBrand(undefined)
            }}
            {...queueJobsModal.modalProps}
          />
        )}
      </Page>
    </main>
  )
}

export default BrandsPage
