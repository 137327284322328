import { useQueryClient } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import { createFlagsmithInstance } from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import React, { useEffect, useMemo } from 'react'

import { trpc } from 'common/hooks/trpc'
import TenantContext from 'common/tenant/TenantContext'
import { overrideFlagsmithInstance } from 'customizer/featureFlags/flagsmith'
import getAPIUrl from 'utils/getAPIUrl'
import getXMCZRHeader from 'utils/getXMCZRHeader'

interface ImpersonateBrandProps {
  brand: string
  children: React.ReactNode
}

const flagsmith = createFlagsmithInstance()

const ImpersonateBrand = ({ brand, children }: ImpersonateBrandProps) => {
  const queryClient = useQueryClient()

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [
          httpBatchLink({
            url: `${getAPIUrl()}/trpc`,
            headers: () => ({ ...getXMCZRHeader(), 'x-tenant-id': brand }),
          }),
        ],
      }),
    [brand]
  )

  useEffect(() => {
    overrideFlagsmithInstance(flagsmith)
    return () => {
      queryClient.invalidateQueries([brand])
    }
  }, [brand])

  if (!brand) return null

  return (
    <FlagsmithProvider options={{ environmentID: FLAGSMITH_PUBLIC_KEY, identity: brand }} flagsmith={flagsmith}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <TenantContext.Provider value={brand}>{children}</TenantContext.Provider>
      </trpc.Provider>
    </FlagsmithProvider>
  )
}

export default ImpersonateBrand
