import { IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

interface BrandAccessesActionsProps {
  onDelete: () => void
}

const BrandAccessesActions = ({ onDelete }: BrandAccessesActionsProps) => {
  const popover = usePopover({ placement: 'top-end', offsetConfig: 8 })

  return (
    <div className="relative">
      <IconButton variant="subtle" {...popover.referenceProps} Icon={MoreIcon} aria-label="More options" small />

      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <Popover.Action
          className="text-tertiary-red-700"
          onClick={() => {
            popover.close()
            onDelete()
          }}
        >
          Delete
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default BrandAccessesActions
