import React from 'react'

import classMerge from '../../utils/classMerge'
import Button, { ButtonProps } from '../button/Button'

export type ClearFiltersButtonProps = Omit<ButtonProps, 'variant' | 'icon'>

const ClearFiltersButton = (props: ClearFiltersButtonProps) => (
  <Button variant="text" {...props} className={classMerge(props.className, { 'opacity-0': props.disabled })}>
    Clear filters
  </Button>
)

export default ClearFiltersButton
