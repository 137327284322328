export enum JobStatus {
  Running = 'running',
  Waiting = 'waiting',
}

export enum JobType {
  Indexing = 'indexing',
  Hubspot = 'hubspot',
  FeatureFlag = 'feature-flag',
  Assets = 'assets',
  Reconciliation = 'reconciliation',
  Other = 'other',
  Shopify = 'shopify',
}

export const jobMetadata: Record<string, { type: JobType; name: string; isDangerous?: boolean }> = {
  indexOnlineStores: { type: JobType.Indexing, name: 'Online stores' },
  indexOrders: { type: JobType.Indexing, name: 'Orders' },
  indexDesigns: { type: JobType.Indexing, name: 'Designs' },
  indexConversionRates: { type: JobType.Indexing, name: 'Conversion rates' },
  indexQuotes: { type: JobType.Indexing, name: 'Quotes' },
  indexProducts: { type: JobType.Indexing, name: 'Products' },
  indexSubscription: { type: JobType.Indexing, name: 'Subscriptions' },
  indexTransactionFees: { type: JobType.Indexing, name: 'Transaction fees' },
  indexUsers: { type: JobType.Indexing, name: 'Users' },
  createOrUpdateDeal: { type: JobType.Hubspot, name: 'Deal' },
  upsertECommerceTrait: { type: JobType.FeatureFlag, name: 'Upsert ecommerce trait' },
  upsertRegisterTrait: { type: JobType.FeatureFlag, name: 'Upsert register trait' },
  regeneratePrintFiles: { type: JobType.Assets, name: 'Regenerate print files' },
  reconcileOrders: { type: JobType.Reconciliation, name: 'Reconcile orders' },
  reconcileStartingPointsBindings: { type: JobType.Reconciliation, name: 'Reconcile starting points' },
  reconcileSubscription: { type: JobType.Reconciliation, name: 'Reconcile subscription' },
  createTransactionFeesInvoiceItems: { type: JobType.Other, name: 'Update transaction fees' },
  updateToAppEmbed: { type: JobType.Shopify, name: 'Update to app embed', isDangerous: true },
  cleanWebhooks: { type: JobType.Shopify, name: 'Clean shopify webhooks' },
  updateToSetCartPriceFunction: { type: JobType.Shopify, name: 'Update to set cart price function', isDangerous: true },
}
