import { useModal } from '@packages/sk8/modal'
import { Table } from '@packages/sk8/table'
import { ToastType, useToast } from '@packages/sk8/toast'
import { AdminUserRole } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import { trpc } from 'common/hooks/trpc'
import * as usersUtils from 'common/users/utils'

import useBrandAccessService from './../hooks/useBrandAccessService'
import BrandAccessesActions from './BrandAccessesActions'
import DeleteAccessModal from './DeleteAccessModal'

const BrandAccesses = () => {
  const queryClient = useQueryClient()
  const brandAccessService = useBrandAccessService()
  const [accessToDelete, setAccessToDelete] = useState<string | null>(null)
  const { openToast, openGenericErrorToast } = useToast()
  const deleteAccessModal = useModal()

  const {
    data: accesses,
    isLoading: isLoadingAccesses,
    isFetching: isFetchingAccesses,
  } = useQuery(brandAccessService.fetchAll.queryKeys, brandAccessService.fetchAll)

  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = trpc.adminUser.list.useQuery({ role: AdminUserRole.MczrAdmin })

  const { mutate: deleteBrandAccess, isLoading: isDeletingBrandAccess } = useMutation(
    () => brandAccessService.delete(accessToDelete!),
    {
      onSuccess: () => {
        setAccessToDelete(null)
        queryClient.invalidateQueries(brandAccessService.fetchAll.queryKeys)
        openToast('Brand access was successfully deleted!', ToastType.success)
      },
      onError: () => {
        setAccessToDelete(null)
        openGenericErrorToast('Brand access has not been deleted.')
      },
    }
  )

  const isLoading = isLoadingAccesses || isLoadingUsers
  const isFetching = isFetchingAccesses || isFetchingUsers

  return (
    <main>
      <Header />
      <SideMenu />

      <Page>
        <Page.Header>
          <h1>Accesses</h1>
        </Page.Header>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-4/12">
                <span>Brand name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-4/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-4/12">
                <span>Email</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-[48px] min-w-[48px]" />
            </Table.HeaderRow>
          </Table.Header>

          {isLoading && <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />}

          {!isLoading && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={4} />}
              {accesses?.map(access => {
                const user = users?.find(user => user.id === access.user)

                return (
                  <Table.Row key={access.id}>
                    <Table.Cell>
                      <div className="min-w-fit">{access.tenant}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">{usersUtils.getFullName(user) || <Table.EmptyCellIndicator />}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">{user?.email || <Table.EmptyCellIndicator />}</div>
                    </Table.Cell>
                    <Table.Cell width={48}>
                      <div className="flex justify-center">
                        <BrandAccessesActions
                          onDelete={() => {
                            setAccessToDelete(access.id)
                            deleteAccessModal.open()
                          }}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          )}
        </Table>
      </Page>

      {deleteAccessModal.isVisible && (
        <DeleteAccessModal
          {...deleteAccessModal.modalProps}
          isLoading={isDeletingBrandAccess}
          onClose={deleteAccessModal.close}
          onDelete={() => {
            deleteAccessModal.close()
            deleteBrandAccess()
          }}
        />
      )}
    </main>
  )
}

export default BrandAccesses
