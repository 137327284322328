import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import Remove from '../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../utils/classMerge'
import { ModalProps } from './Modal'

export interface FullPageModalProps extends ModalProps {
  onClose?: () => void
  className?: string
  headerName?: string
}

const FullPageModal = ({ onClose, isOpen, headerName, className, unmount, children }: FullPageModalProps) => {
  useEffect(() => {
    if (!isOpen) unmount() // normal modal unmount after animation, this modal doesn't use animation
  }, [isOpen])

  return createPortal(
    <RemoveScroll>
      <div
        role="dialog"
        tabIndex={0}
        className={classMerge(
          'fixed top-0 left-0 right-0 bottom-0 m-auto flex flex-col overflow-auto items-center w-full h-full bg-white z-50',
          className
        )}
      >
        {onClose && (
          <div className="border-b border-neutral-100 w-full flex items-start p-4 space-x-6 sticky top-0 bg-white z-50">
            <button role="button" aria-label="modal close" type="button" onClick={onClose}>
              <Remove className="fill-black" width={14} height={14} />
            </button>
            <h2 className="text-neutral-600 font-medium">{headerName}</h2>
          </div>
        )}
        {children}
      </div>
    </RemoveScroll>,
    document.body
  )
}

export default FullPageModal
