import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import type { BrandAccess } from './../types/brandAccess'
import type { CreateBrandAccessFormValues } from './../types/form'

export type CreateBrandAccessResponse = { tenant: string; user: string; id: string }

const useBrandAccessService = () => {
  return {
    fetchAll: createQuery(['brand-accesses'], async () => {
      const { data } = await apiClient.get<BrandAccess[]>('/admin/accesses')
      return data
    }),
    fetchAllForUser: createQuery(['brand-accesses', 'user'], async () => {
      const { data } = await apiClient.get<BrandAccess[]>('/admin/users/me/accesses')
      return data
    }),
    create: async (newAccess: CreateBrandAccessFormValues) => {
      const { data } = await apiClient.post<CreateBrandAccessResponse>('/admin/accesses', newAccess)
      return data
    },
    delete: async (id: string) => {
      return apiClient.delete<void, void>(`/admin/accesses/${id}`)
    },
  }
}

export default useBrandAccessService
