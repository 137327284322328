import React from 'react'

const StoreUrlsTooltip = ({ urls }: { urls: string[] }) => (
  <div className="flex flex-col space-y-2">
    {urls.map(url => (
      <a
        key={url}
        href={url.includes('://') ? url : `https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
        className="text-primary-500"
      >
        {url}
      </a>
    ))}
  </div>
)

export default StoreUrlsTooltip
