import { money } from '@packages/money'
import { BlankState } from '@packages/sk8/blank-state'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { Tooltip } from '@packages/sk8/tooltip'
import classNames from 'classnames'
import { compact, pick, uniq } from 'lodash'
import React from 'react'

import ImpersonateBrand from 'cms/admin/components/ImpersonateBrand'
import ECommerceLogo from 'cms/onlineStores/components/ECommerceLogo'
import type { Paginated } from 'common/api/types/pagination'
import { SortOrder } from 'common/hooks/dataTable/useTableSort'
import BrandIcon from 'icons/regular/09-Shopping-Ecommerce/07-Shopping-Bags/shopping-bag-side.svg'
import { formatSmallDate } from 'utils/dateUtils'

import type { BrandsSortKeys } from './../types/dataTable'
import type { Tenant } from './../types/tenant'
import BrandsActions, { BrandsActionsDef } from './BrandsActions'
import { CONVERSION_RATE_FILTERS, PLAN_FILTERS, STATUS_FILTERS } from './BrandsFilterList'
import StoreUrlsTooltip from './StoreUrlsTooltip'
import SubscriptionTooltip from './SubscriptionTooltip'

type BrandColumn = {
  key: BrandsSortKeys
  className: string
  cellClassName?: string
  title: string
  isSortable: boolean
  render: (tenant: Tenant) => React.ReactNode
}

export const columns: BrandColumn[] = [
  {
    key: 'eCommerceTypes',
    title: 'Store(s)',
    className: 'w-1/13',
    isSortable: false,
    render: (tenant: Tenant) => {
      const logos = compact(uniq(tenant.eCommerceTypes).sort()).map(type => {
        const onlineStoreUrls = tenant.onlineStoreUrls
          .filter(url => url.eCommerce === type && url.url)
          .map(url => url.url)
        return (
          <Tooltip key={type} content={<StoreUrlsTooltip urls={onlineStoreUrls} />} placement="left">
            <ECommerceLogo className="h-5 w-5 mr-1" key={type} eCommerce={type} />
          </Tooltip>
        )
      })

      return (
        <div className="flex" data-testid="online-store-logo">
          {logos.length === 0 ? <Table.EmptyCellIndicator /> : logos}
        </div>
      )
    },
  },
  {
    key: 'name',
    title: 'Brand',
    className: 'w-2/13',
    isSortable: true,
    render: (tenant: Tenant) => (
      <div className="flex items-center">
        {tenant.name}
        <div className="flex space-x-2 ml-4 mr-4">
          {tenant.isTest && <Tag className={classNames('rounded bg-tertiary-green-400 text-white')}>Test</Tag>}
          {tenant.hasFraudRisk && (
            <Tag className={classNames('rounded bg-tertiary-red-400 text-white')}>Fraud risk</Tag>
          )}
        </div>
      </div>
    ),
  },
  {
    key: 'indexing.totalOrders',
    title: 'Orders',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) =>
      tenant.totalOrders !== undefined ? tenant.totalOrders.toLocaleString() : <Table.EmptyCellIndicator />,
  },
  {
    key: 'indexing.totalDesigns',
    title: 'Designs',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) =>
      tenant.totalDesigns !== undefined ? tenant.totalDesigns.toLocaleString() : <Table.EmptyCellIndicator />,
  },
  {
    key: 'indexing.conversionRate',
    title: 'Conversion rate',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) => {
      if (tenant.conversionRate == null) return <Table.EmptyCellIndicator />

      const range = CONVERSION_RATE_FILTERS.find(rate => {
        return tenant.conversionRate >= rate.range[0] && tenant.conversionRate <= rate.range[1]
      })

      return (
        <Tooltip content={`${(tenant.conversionRate * 100).toFixed(2)}%`} placement="top">
          <Tag className={classNames('rounded', range?.className)}>{range?.name || <Table.EmptyCellIndicator />}</Tag>
        </Tooltip>
      )
    },
  },
  {
    key: 'indexing.totalQuotes',
    title: 'Quotes',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) =>
      tenant.totalQuotes !== undefined ? tenant.totalQuotes.toLocaleString() : <Table.EmptyCellIndicator />,
  },
  {
    key: 'indexing.totalTransactionFees',
    title: 'Total tx. fees',
    className: 'w-2/13',
    isSortable: true,
    render: (tenant: Tenant) => money(tenant.totalTransactionFees || 0).toFormattedString(),
  },
  {
    key: 'createdAt',
    title: 'Created',
    className: 'w-2/13',
    cellClassName: 'text-neutral-700',
    isSortable: true,
    render: (tenant: Tenant) => (tenant.createdAt ? formatSmallDate(tenant.createdAt) : <Table.EmptyCellIndicator />),
  },
  {
    key: 'indexing.productsLastUpdatedAt',
    title: 'Last update',
    className: 'w-2/13',
    cellClassName: 'text-neutral-700',
    isSortable: true,
    render: (tenant: Tenant) =>
      tenant.productsLastUpdatedAt ? formatSmallDate(tenant.productsLastUpdatedAt) : <Table.EmptyCellIndicator />,
  },
  {
    key: 'indexing.subscription.status',
    title: 'Status',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) => {
      const status = STATUS_FILTERS.find(({ name }) => name === tenant.status)
      return (
        <Tag className={classNames('rounded', status?.className)}>{status?.text || <Table.EmptyCellIndicator />}</Tag>
      )
    },
  },
  {
    key: 'indexing.subscription.plan',
    title: 'Plan',
    className: 'w-1/13',
    isSortable: true,
    render: (tenant: Tenant) => {
      const plan = PLAN_FILTERS.find(({ name }) => name === tenant.plan.name)
      return (
        <Tooltip content={<SubscriptionTooltip addons={tenant.addons} />} placement="left">
          <Tag className={classNames('rounded', plan?.className)}>{plan?.text || <Table.EmptyCellIndicator />}</Tag>
        </Tooltip>
      )
    },
  },
]

export interface BrandsTableProps {
  sortKey?: BrandsSortKeys
  sortOrder?: SortOrder
  handleSort: (key: BrandsSortKeys) => void
  setCount: (value: number) => void
  nextPage: () => void
  previousPage: () => void
  count: number
  lastIndex: number
  resultSize?: number
  collectionSize?: number
  data?: Paginated<Tenant>
  isLoading?: boolean
  isFetching?: boolean
  getAccessLevelForTenant: (tenantName: string) => 'master' | 'admin' | null
  onClearFilters: () => void
  brandsActions: BrandsActionsDef & { click: (tenantName: string) => void }
}

const BrandsTable = ({
  sortKey,
  sortOrder,
  handleSort,
  setCount,
  count,
  previousPage,
  nextPage,
  data,
  lastIndex,
  collectionSize,
  resultSize,
  isLoading,
  isFetching,
  onClearFilters,
  getAccessLevelForTenant,
  brandsActions,
}: BrandsTableProps) => {
  const isEmpty = !data?.results.length
  const numberOfColumns = columns.length + 1

  return (
    <Table>
      <Table.Header>
        <Table.HeaderRow>
          {columns.map(column => {
            return (
              <Table.HeaderCell key={column.key} className={column.className}>
                <Table.HeaderFilter
                  onClick={() => handleSort(column.key)}
                  sortOrder={sortOrder}
                  isSortable={column.isSortable}
                  isSorted={sortKey === column.key}
                  id={`sort-${column.key}`}
                  aria-label={sortKey === column.key ? `Sorted by ${column.title} ${sortOrder}` : ''}
                >
                  {column.title}
                </Table.HeaderFilter>
              </Table.HeaderCell>
            )
          })}
          <Table.HeaderCell className="w-[48px] min-w-[48px]" />
        </Table.HeaderRow>
      </Table.Header>

      {isLoading && <Table.BodyLoader numberOfColumns={numberOfColumns} numberOfRows={count} />}

      {!isLoading && isEmpty && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={numberOfColumns}>
              <div className="flex items-center justify-center py-32">
                <BlankState>
                  <BlankState.Icon Icon={BrandIcon} />
                  <BlankState.Title>No brands to display with the specified filters</BlankState.Title>
                  <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
                  <BlankState.Action onClick={onClearFilters}>Clear all filters</BlankState.Action>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}

      {!isLoading && !isEmpty && (
        <>
          <Table.Body>
            {isFetching && <Table.Loader colSpan={numberOfColumns} />}
            {data?.results.map(tenant => {
              const accessLevel = getAccessLevelForTenant(tenant.name)

              return (
                <Table.Row
                  key={tenant.id}
                  className={accessLevel ? 'cursor-pointer' : ''}
                  onClick={() => brandsActions.click(tenant.name)}
                >
                  {columns.map(column => {
                    const isSorted = sortKey === column.key

                    return (
                      <Table.Cell key={column.key} className={column.cellClassName}>
                        <div className={classNames('min-w-fit', { 'font-medium': isSorted })}>
                          {column.render(tenant)}
                        </div>
                      </Table.Cell>
                    )
                  })}

                  {accessLevel && (
                    <Table.Cell width={48}>
                      <div className="flex justify-center">
                        <ImpersonateBrand brand={tenant.name}>
                          <BrandsActions
                            hasFraudRisk={!!tenant.hasFraudRisk}
                            actions={accessLevel === 'master' ? brandsActions : pick(brandsActions, ['queueJobs'])}
                          />
                        </ImpersonateBrand>
                      </div>
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer>
            <Table.FooterRow>
              <Table.FooterCell colSpan={numberOfColumns}>
                <Table.Pagination>
                  <Table.PaginationRowsPerPage onChange={setCount} rowsPerPage={count} />
                  <Table.PaginationNavigation
                    onNextPage={nextPage}
                    onPreviousPage={previousPage}
                    lastIndex={lastIndex}
                    resultSize={resultSize || 0}
                    count={count}
                    collectionSize={collectionSize || 0}
                  />
                </Table.Pagination>
              </Table.FooterCell>
            </Table.FooterRow>
          </Table.Footer>
        </>
      )}
    </Table>
  )
}

export default BrandsTable
