import React, { useState, useRef } from 'react'
import ReactDatePicker from 'react-datepicker'

import classMerge from '../../../utils/classMerge'
import Input from '../Input'
import DatePickerIcon from './DatePickerIcon'
import DatePickerInput, { DatePickerInputProps } from './DatePickerInput'

import 'react-datepicker/dist/react-datepicker.min.css'
import '../datePicker/react-datepicker.css'

export interface DateRangePickerInputProps {
  className?: string
  disabled?: boolean
  hasError?: boolean
  startDatePickerProps: DatePickerInputProps
  endDatePickerProps: DatePickerInputProps
}

type ReactDatePickerRef = ReactDatePicker & {
  handleBlur: () => void
  handleFocus: () => void
  input: HTMLInputElement
}

const DateRangePickerInput = ({
  className,
  disabled,
  hasError,
  startDatePickerProps,
  endDatePickerProps,
}: DateRangePickerInputProps) => {
  const { selected: startDate, onValueChange: onStartChange } = startDatePickerProps
  const { selected: endDate, onValueChange: onEndChange } = endDatePickerProps
  const startDateRef = useRef<ReactDatePickerRef | null>(null)
  const endDateRef = useRef<ReactDatePickerRef | null>(null)

  const [isFocused, setFocused] = useState(false)

  const blurFields = () => {
    startDateRef.current?.setOpen(false)
    endDateRef.current?.setOpen(false)
    startDateRef.current?.handleBlur()
    endDateRef.current?.handleBlur()
  }

  const handleSelectStartDate = (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => {
    startDatePickerProps.onSelect?.(date, event)
    endDateRef.current?.setOpen(true)
    endDateRef.current?.input.focus()
  }

  const handleClearDates = () => {
    onStartChange(null)
    onEndChange(null)
    blurFields()
  }

  const handleCalendarIconClick = () => {
    blurFields()
    startDateRef.current?.input.focus()
  }

  return (
    <div
      className={classMerge('flex rounded-lg w-full', className, { 'ring ring-primary-100': isFocused })}
      role="presentation"
      aria-label="date range picker container"
    >
      <DatePickerInput
        customInput={
          <Input
            inputClassName="border-b-2 border-t-2 border-transparent py-0.5"
            focusClassName="border-neutral-300 border-t-transparent"
            className={classMerge('rounded-tr-none rounded-br-none ring-0 !border-r-0 pl-2 pr-2', {
              '!border-neutral-100': isFocused,
            })}
            aria-label="start-picker"
            hasError={hasError}
          />
        }
        autoComplete="off"
        disabled={disabled}
        disabledKeyboardNavigation
        endDate={endDate}
        inputRef={startDateRef}
        onBlur={() => setFocused(false)}
        onCalendarOpen={() => setFocused(true)}
        onClickOutside={blurFields}
        onFocus={() => setFocused(true)}
        placeholderText="Start"
        selected={startDate}
        selectsStart
        startDate={startDate}
        onSelect={handleSelectStartDate}
        {...startDatePickerProps}
      />
      <DatePickerInput
        customInput={
          <Input
            inputClassName="border-b-2 border-t-2 border-transparent py-0.5"
            focusClassName="border-neutral-300 border-t-transparent"
            className={classMerge(
              'rounded-tl-none rounded-bl-none ring-0 !border-l-0',
              'before:w-[1px] before:h-auto before:bg-neutral-100 before:absolute before:top-1 before:bottom-1 before:left-0',
              {
                '!border-neutral-100': isFocused,
              }
            )}
            aria-label="end-picker"
            rightAddon={
              <DatePickerIcon
                onCalendarClick={handleCalendarIconClick}
                onClearDate={handleClearDates}
                showRemoveIcon={!!startDate || !!endDate}
                disabled={disabled}
              />
            }
            hasError={hasError}
          />
        }
        autoComplete="off"
        disabled={disabled}
        disabledKeyboardNavigation
        endDate={endDate}
        inputRef={endDateRef}
        minDate={startDate}
        onBlur={() => setFocused(false)}
        onCalendarOpen={() => setFocused(true)}
        onClickOutside={blurFields}
        onFocus={() => setFocused(true)}
        placeholderText="End"
        selected={endDate}
        selectsEnd
        startDate={startDate}
        {...endDatePickerProps}
      />
    </div>
  )
}

export default DateRangePickerInput
