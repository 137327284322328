import React from 'react'

import { addUnits } from './utils'

export interface TimeSummaryProps {
  data: { average: number; averageChangePercent: number; data: { value: number; deltaPercent: number }[] }
  units?: string
  positivity?: number
}

const TimeSummary = ({ data, units, positivity }: TimeSummaryProps) => {
  const getColor = (delta: number) => {
    return positivity === 1 ? (delta > 0 ? 'green' : 'red') : delta > 0 ? 'red' : 'green'
  }

  if (data.data.length === 0) return null

  return (
    <div className="flex flex-col space-y-8">
      <div className="grid grid-cols-2 gap-8">
        <div>
          <div className="text-neutral-500">Average</div>
          <div className="text-lg">{addUnits(data?.average, units)}</div>
        </div>
        <div className="div">
          <div className="text-neutral-500">Average change</div>
          <div className="text-lg" style={{ color: getColor(data.averageChangePercent) }}>
            {data.averageChangePercent}%
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div>
          <div className="text-neutral-500">Last month</div>
          <div className="text-lg">{addUnits(data?.data[data?.data.length - 1].value, units)}</div>
        </div>
        <div>
          <div className="text-neutral-500">Last month change</div>
          <div className="text-lg" style={{ color: getColor(data?.data[data?.data.length - 1].deltaPercent) }}>
            {data?.data[data?.data.length - 1].deltaPercent}%
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeSummary
