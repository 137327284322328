import { IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import { SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useContext } from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import TenantContext from 'common/tenant/TenantContext'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

export type BrandsActionsDef = {
  extendFreeTrial?: (brandName: string) => void
  grantAdminAccess?: (brandName: string) => void
  edit?: (brandName: string) => void
  toggleFraudRisk?: (brandName: string) => void
  queueJobs?: (brandName: string) => void
}

export type BrandsActionsProps = {
  hasFraudRisk: boolean
  actions: BrandsActionsDef
}

const BrandsActions = ({ hasFraudRisk, actions }: BrandsActionsProps) => {
  const popover = usePopover({ placement: 'top-end', offsetConfig: 8 })
  const subscriptionService = useSubscriptionService()
  const tenant = useContext(TenantContext)

  const { data: subscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    () => {
      if (actions.extendFreeTrial) return subscriptionService.fetchOne()
      return null
    },
    {
      enabled: popover.isOpen,
    }
  )

  const wasCancelledDuringFreeTrial =
    subscription?.status === SubscriptionStatus.Canceled && subscription.canceledDuringTrial === true

  const canExtendFreeTrial =
    !!subscription?.paymentStrategy &&
    (wasCancelledDuringFreeTrial || subscription.status === SubscriptionStatus.FreeTrial)

  return (
    <div className="relative" data-testid="row-actions">
      <IconButton variant="subtle" {...popover.referenceProps} Icon={MoreIcon} small aria-label="more" />

      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        {actions?.extendFreeTrial && (
          <Popover.Action
            disabled={!canExtendFreeTrial}
            onClick={() => {
              popover.close()
              if (canExtendFreeTrial) actions.extendFreeTrial!(tenant)
            }}
          >
            Extend free trial
          </Popover.Action>
        )}
        {actions?.grantAdminAccess && (
          <Popover.Action
            onClick={() => {
              popover.close()
              actions.grantAdminAccess!(tenant)
            }}
          >
            Grant admin access
          </Popover.Action>
        )}
        {actions?.queueJobs && (
          <Popover.Action
            onClick={() => {
              popover.close()
              actions.queueJobs!(tenant)
            }}
          >
            Queue jobs
          </Popover.Action>
        )}
        {actions?.edit && (
          <Popover.Action
            onClick={() => {
              popover.close()
              actions.edit!(tenant)
            }}
          >
            Edit
          </Popover.Action>
        )}

        {actions?.toggleFraudRisk && (
          <Popover.Action
            onClick={() => {
              popover.close()
              actions.toggleFraudRisk!(tenant)
            }}
          >
            {hasFraudRisk ? 'Clear fraud risk' : 'Mark as fraud risk'}
          </Popover.Action>
        )}
      </Popover>
    </div>
  )
}

export default BrandsActions
