import {
  CheckboxFilter,
  ClearFiltersButton,
  DatesFilter,
  Filter,
  FilterContainer,
  FilterList,
  NumberFilter,
  NumberFilterValue,
  SearchFilter,
  TagsFilter,
} from '@packages/sk8/filter'
import {
  AddonId,
  AddonName,
  BrandTenantConversionRate,
  BrandTenantConversionRateRanges,
  ECommerce,
  PlanName,
  SubscriptionStatus,
} from '@packages/types'
import React from 'react'

import ECommerceLogo from 'cms/onlineStores/components/ECommerceLogo'
import FilterIcon from 'icons/bold/01-Interface Essential/15-Filter/filter-2.svg'

import type { BrandsFilter, BrandsFilters } from './../types/dataTable'

export const CONVERSION_RATE_FILTERS: (BrandsFilter<BrandTenantConversionRate> & { range: number[] })[] = [
  {
    name: BrandTenantConversionRate.VeryLow,
    range: BrandTenantConversionRateRanges[BrandTenantConversionRate.VeryLow],
    text: 'Very low',
    className: 'fill-tertiary-red-700 bg-tertiary-red-100 text-tertiary-red-700',
  },
  {
    name: BrandTenantConversionRate.Low,
    range: BrandTenantConversionRateRanges[BrandTenantConversionRate.Low],
    text: 'Low',
    className: 'fill-secondary-orange-700 bg-warning-light bg-secondary-orange-100 text-secondary-orange-700',
  },
  {
    name: BrandTenantConversionRate.Medium,
    range: BrandTenantConversionRateRanges[BrandTenantConversionRate.Medium],
    text: 'Medium',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
  {
    name: BrandTenantConversionRate.High,
    range: BrandTenantConversionRateRanges[BrandTenantConversionRate.High],
    text: 'High',
    className: 'fill-tertiary-green-700 bg-tertiary-green-100 text-tertiary-green-700',
  },
]

export const STATUS_FILTERS: BrandsFilter<SubscriptionStatus>[] = [
  {
    name: SubscriptionStatus.FreeTrial,
    text: 'Free trial',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
  {
    name: SubscriptionStatus.Future,
    text: 'Future',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
  {
    name: SubscriptionStatus.Active,
    text: 'Active',
    className: 'fill-tertiary-green-700 bg-tertiary-green-100 text-tertiary-green-700',
  },
  {
    name: SubscriptionStatus.Expired,
    text: 'Expired',
    className: 'bg-neutral-75',
  },
  {
    name: SubscriptionStatus.Canceled,
    text: 'Canceled',
    className: 'fill-tertiary-red-700 bg-tertiary-red-100 text-tertiary-red-700',
  },
  {
    name: SubscriptionStatus.RequestCancel,
    text: 'Non-renewing',
    className: 'fill-secondary-orange-700 bg-warning-light bg-secondary-orange-100 text-secondary-orange-700',
  },
]

export const PLAN_FILTERS: BrandsFilter<PlanName>[] = [
  { name: PlanName.Essential, text: 'Essential', className: 'bg-neutral-50 text-neutral-700' },
  { name: PlanName.Startup, text: 'Startup', className: 'bg-neutral-100 text-neutral-800' },
  { name: PlanName.Pro, text: 'Pro', className: 'bg-neutral-200 text-neutral-800' },
  { name: PlanName.Business, text: 'Business', className: 'bg-neutral-600 text-white', iconClassName: 'fill-white' },
  {
    name: PlanName.Custom,
    text: 'Enterprise',
    className: 'bg-neutral-800 text-white',
    iconClassName: 'fill-white',
  },
  { name: PlanName.PayAsYouGrow, text: 'Pay as you grow', className: 'bg-neutral-100 text-neutral-800' },
  { name: PlanName.Scale, text: 'Scale', className: 'bg-neutral-200 text-neutral-800' },
]

export const ADDON_FILTERS: BrandsFilter<AddonId>[] = [
  {
    name: AddonId.WhiteLabel,
    text: AddonName.WhiteLabel,
    className: 'bg-neutral-100 text-neutral-800',
  },
  {
    name: AddonId.ConfigurePriceQuote,
    text: AddonName.CPQ,
    className: 'bg-neutral-800 text-white',
  },
]

export const ECOMMERCE_TYPES_FILTERS: BrandsFilter<ECommerce>[] = [
  {
    name: ECommerce.Shopify,
    text: (
      <div className="flex space-x-1 items-center">
        <ECommerceLogo className="h-3 w-3 mr-1" eCommerce={ECommerce.Shopify} />
        Shopify
      </div>
    ),
    className: 'bg-white border border-neutral-100 text-neutral-800',
  },
  {
    name: ECommerce.Prestashop,
    text: (
      <div className="flex space-x-1 items-center">
        <ECommerceLogo className="h-3 w-3 mr-1" eCommerce={ECommerce.Prestashop} />
        PrestaShop
      </div>
    ),
    className: 'bg-white border border-neutral-100 text-neutral-800',
  },
  {
    name: ECommerce.Woocommerce,
    text: (
      <div className="flex space-x-1 items-center">
        <ECommerceLogo className="h-3 w-3 mr-1" eCommerce={ECommerce.Woocommerce} />
        WooCommerce
      </div>
    ),
    className: 'bg-white border border-neutral-100 text-neutral-800',
  },
  {
    name: ECommerce.Wix,
    text: (
      <div className="flex space-x-1 items-center">
        <ECommerceLogo className="h-3 w-3 mr-1" eCommerce={ECommerce.Wix} />
        Wix
      </div>
    ),
    className: 'bg-white border border-neutral-100 text-neutral-800',
  },
  {
    name: ECommerce.CustomStore,
    text: 'Custom Store',
    className: 'bg-white border border-neutral-100 text-neutral-800',
  },
]

export const VARIOUS_FILTERS: Filter[] = [
  {
    name: 'isTest',
    text: 'Test brand',
  },
  {
    name: 'hasFraudRisk',
    text: 'Fraud risk',
  },
]

export interface BrandsFilterListProps extends BrandsFilters {
  toggleFilter: (type: keyof BrandsFilters, name: string) => void
  setFilter: (type: keyof BrandsFilters, value: NumberFilterValue) => void
  clearFilters: (type: keyof BrandsFilters) => void
  setSearch: (value: string) => void
  search?: string
  startDate?: Date | null
  setStartDate: (value?: Date | null) => void
  endDate?: Date | null
  setEndDate: (value?: Date | null) => void
  clearAll: () => void
  hasAppliedFilters?: boolean
}

const BrandsFilterList = ({
  status,
  plans,
  addons,
  eCommerceTypes,
  various,
  toggleFilter,
  clearFilters,
  clearAll,
  hasAppliedFilters,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  search,
  setSearch,
  orders,
  designs,
  conversionRates,
  quotes,
  setFilter,
}: BrandsFilterListProps) => {
  return (
    <FilterContainer>
      <FilterList>
        <SearchFilter name="Search" setSearch={setSearch} search={search} />
        <DatesFilter
          name="Created"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <TagsFilter
          name="Status"
          filters={STATUS_FILTERS}
          activeFilters={status}
          toggleFilter={name => toggleFilter('status', name)}
          clearFilter={() => clearFilters('status')}
        />
        <TagsFilter
          name="Plan"
          filters={PLAN_FILTERS}
          activeFilters={plans}
          toggleFilter={name => toggleFilter('plans', name)}
          clearFilter={() => clearFilters('plans')}
        />
        <TagsFilter
          name="Addon"
          filters={ADDON_FILTERS}
          activeFilters={addons}
          toggleFilter={name => toggleFilter('addons', name)}
          clearFilter={() => clearFilters('addons')}
        />
        <TagsFilter
          name="Store"
          filters={ECOMMERCE_TYPES_FILTERS}
          activeFilters={eCommerceTypes}
          toggleFilter={name => toggleFilter('eCommerceTypes', name)}
          clearFilter={() => clearFilters('eCommerceTypes')}
        />
        <NumberFilter
          activeFilter={orders}
          setFilter={filter => setFilter('orders', filter)}
          clearFilter={() => clearFilters('orders')}
          customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
          name="Orders"
        />
        <NumberFilter
          activeFilter={designs}
          setFilter={filter => setFilter('designs', filter)}
          clearFilter={() => clearFilters('designs')}
          customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
          name="Designs"
        />
        <TagsFilter
          filters={CONVERSION_RATE_FILTERS}
          activeFilters={conversionRates}
          toggleFilter={name => toggleFilter('conversionRates', name)}
          clearFilter={() => clearFilters('conversionRates')}
          name="Conversion rate"
        />
        <NumberFilter
          activeFilter={quotes}
          setFilter={filter => setFilter('quotes', filter)}
          clearFilter={() => clearFilters('quotes')}
          customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
          name="Quotes"
        />
        <CheckboxFilter
          name=""
          Icon={FilterIcon}
          filters={VARIOUS_FILTERS}
          activeFilters={various}
          toggleFilter={name => toggleFilter('various', name)}
          clearFilter={() => clearFilters('various')}
        />
      </FilterList>
      <ClearFiltersButton onClick={clearAll} disabled={!hasAppliedFilters} />
    </FilterContainer>
  )
}

export default BrandsFilterList
