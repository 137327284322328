import { Button, IconButton } from '@packages/sk8/button'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { ToastType, useToast } from '@packages/sk8/toast'
import { AdminUserRole } from '@packages/types'
import React from 'react'
import { useParams } from 'react-router-dom'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import ChangePasswordForm from 'cms/users/components/ChangePasswordForm'
import EditAccountForm from 'cms/users/components/EditAccountForm'
import { trpc } from 'common/hooks/trpc'
import useGoBack from 'common/hooks/useGoBack'
import * as usersUtils from 'common/users/utils'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

const roleOptions = [{ value: AdminUserRole.MczrAdmin, label: 'MCZR Admin' }]

const EditAdminUser = () => {
  const { id } = useParams<{ id: string }>()
  const goBack = useGoBack()
  const { openToast, openGenericErrorToast } = useToast()
  const popover = usePopover({ placement: 'bottom-end', offsetConfig: 8 })
  const deleteAdminUserModal = useModal()
  const trpcUtils = trpc.useContext()

  const { data: user, isLoading } = trpc.adminUser.get.useQuery(id)

  const { mutate: updateUser } = trpc.adminUser.update.useMutation({
    onSuccess: updatedUser => {
      trpcUtils.adminUser.get.setData(id, updatedUser)
      openToast('User was successfully updated!', ToastType.success)
    },
    onError: (error, { changeSet: { email } }) => {
      if (error.data?.code === 'CONFLICT') {
        openToast(`The email ${email} is already in use by another user`, ToastType.warning)
      }
      if (error.data?.code === 'FORBIDDEN') {
        openToast('Can not update master user', ToastType.warning)
      } else {
        openGenericErrorToast('User has not been updated.')
      }
    },
  })

  const { mutate: changePassword } = trpc.adminUser.changePassword.useMutation({
    onSuccess: () => openToast('User password was successfully changed!', ToastType.success),
    onError: () => openGenericErrorToast('Password has not been changed.'),
  })

  const { mutate: deleteUser, isLoading: isDeletingUser } = trpc.adminUser.delete.useMutation({
    onSuccess: () => {
      openToast('User was successfully deleted!', ToastType.success)
      goBack()
    },
    onError: error => {
      if (error.data?.code === 'FORBIDDEN') {
        openToast('Could not delete user. Master users are not deletable.', ToastType.warning)
      } else {
        openGenericErrorToast('User has not been deleted.')
      }
    },
  })

  return (
    <main>
      <Header />
      <SideMenu />

      {user && !isLoading && (
        <>
          <Page>
            <Page.Header className="flex justify-between items-center mb-10">
              <div className="flex items-center">
                <IconButton Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
                <h1>
                  {user.firstName}&nbsp;{user.lastName}
                </h1>
              </div>
              {user.role === AdminUserRole.MczrAdmin && (
                <div className="flex relative">
                  <IconButton {...popover.referenceProps} Icon={MoreIcon} aria-label="More options" />
                  <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
                    <Popover.Action
                      className="text-tertiary-red-700"
                      onClick={() => {
                        popover.close()
                        deleteAdminUserModal.open()
                      }}
                    >
                      Delete
                    </Popover.Action>
                  </Popover>
                </div>
              )}
            </Page.Header>

            <Page.Section>
              <Page.Aside title="Account" description="Generic informations, we won't show this to anyone" />
              <Page.Content>
                <EditAccountForm
                  user={user}
                  onSubmit={(values, helpers) =>
                    updateUser(
                      { id, changeSet: { ...values, role: values.role as AdminUserRole.MczrAdmin } },
                      { onSettled: () => helpers.setSubmitting(false) }
                    )
                  }
                  roleOptions={roleOptions}
                />
              </Page.Content>
            </Page.Section>

            <Page.Separator />

            <Page.Section>
              <Page.Aside title="Change password" description="Don't forget it after changing it!" />
              <Page.Content>
                <ChangePasswordForm
                  onSubmit={(values, helpers) =>
                    changePassword(
                      { id, password: values.password },
                      { onSettled: () => helpers.setSubmitting(false), onSuccess: () => helpers.resetForm() }
                    )
                  }
                />
              </Page.Content>
            </Page.Section>
          </Page>

          {deleteAdminUserModal.isVisible && (
            <Modal onBackdropClick={deleteAdminUserModal.close} {...deleteAdminUserModal.modalProps}>
              <Modal.CloseButton onClick={deleteAdminUserModal.close} />
              <Modal.Title>Delete user</Modal.Title>

              <Modal.Details>
                Are you sure you want to delete the user {usersUtils.getFullName(user)}? This action cannot be undone.
              </Modal.Details>

              <Modal.Actions>
                <Button
                  type="button"
                  variant="default"
                  className="px-4"
                  onClick={deleteAdminUserModal.close}
                  disabled={isDeletingUser}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="error"
                  className="px-4"
                  onClick={() => deleteUser(id)}
                  isLoading={isDeletingUser}
                  disabled={isDeletingUser}
                >
                  Delete
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
    </main>
  )
}

export default EditAdminUser
